import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { CustomButton } from "./CustomButton";
import { message } from "antd";

const env = process.env.REACT_APP_ENVIRONMENT;
const config = require("../config/config.json")[env];

const ShareLinkColumnData = ({ record }) => {
  const [showCopyButton, setShowCopyButton] = useState(false);

  const handleMouseEnter = () => {
    setShowCopyButton(true);
  };

  const handleMouseLeave = () => {
    setShowCopyButton(false);
  };

  const shareLinkCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `${config.open_coupon_url}${record.encrypted_key}`
      );
      message.success({
        content: "링크복사가 완료되었습니다.",
        style: {
          marginTop: "5vh",
        },
        duration: 1,
      });
    } catch (e) {
      console.log(e);
    }
  };

  if (!record.is_share_coupon_url) {
    return null;
  }

  return (
    <CustomColumn
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {record.encrypted_key
        ? `${config.open_coupon_url}${record.encrypted_key}`
        : null}
      {showCopyButton && (
        <CustomButton
          onClick={shareLinkCopy}
          position="absolute"
          width="70px"
          height="28px"
          borderRadius="5px"
          background="#4a4a4a"
          text="링크 복사"
          fontSize="12px"
        />
      )}
    </CustomColumn>
  );
};

const commonColumnStyle = `
font-family: "Noto Sans KR-Regular";
font-style: normal;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
justify-content: center;
text-align: center; `;

const CustomColumn = styled.div`
  ${commonColumnStyle}
`;

export default ShareLinkColumnData;
