import React from "react";
import { Form, Input, Dropdown, Menu } from "antd";
import { Text, Icon, CloseButton } from "../styles/commonStyle";
import { CustomButton } from "./CustomButton";
import styled, { keyframes } from "styled-components";
import { commonTheme } from "../styles/theme";
const NumberTypeData = ({
  selectedOption,
  count,
  idx,
  handleCountChange,
  setNumberTypeList,
  numberTypeList,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "50%",
        alignItems: "center",
        justifyContent: "space-around",
        marginTop: idx > 0 ? "12px" : "0px",
      }}
    >
      <Text fontWeight={700}>{selectedOption.label}</Text>
      <Form.Item>
        <Input
          onBlur={() => {
            if (Number(numberTypeList[idx].count) === 0) {
              let newList = numberTypeList;
              newList[idx].count = 1;
              setNumberTypeList([...newList]);
            }
          }}
          style={{
            width: "50px",
            height: "45px",
            textAlign: "center",
            //   opacity: dateCountTiming === "EQUAL" ? "50%" : null,
          }}
          // disabled={dateCountTiming === "EQUAL" ? true : false}
          autoComplete="off"
          value={count}
          onChange={(e) => handleCountChange(e, idx)}
        />
      </Form.Item>

      <Text fontWeight={700}>회 전에 전송 됩니다.</Text>
      <CloseButton
        onClick={() => {
          let newNumberTypeList = [...numberTypeList];
          if (newNumberTypeList && newNumberTypeList.length > 0) {
            if (newNumberTypeList.length <= 1) {
              return;
            }
            newNumberTypeList.splice(idx, 1);
            setNumberTypeList(newNumberTypeList);
          }
        }}
        type="button"
      >
        <CloseIcon
          src={process.env.PUBLIC_URL + "/assets/images/icon/white/x.png"}
        ></CloseIcon>
      </CloseButton>
    </div>
  );
};
const antMoveDownIn = keyframes`
  0% {
    transform-origin: 0 0;
    transform: translateY(0);
    opacity: 1;
  }
  100%{
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const antMoveDownOut = keyframes`
  0% {
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    transform-origin: 0 0;
    transform: translateY(0);
    opacity: 1;
  }
`;

const ButtonStyle = `
flex-grow: 1;
border: none;
font-family: "Noto Sans KR";
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 29px;
text-align: center;
letter-spacing: -0.670707px;
:hover {
  cursor: pointer;
}
`;

const CustomSelect = styled.div`
  width: 329px;
  height: 45px;
  color: #a4a4a4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #4a4a4a;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 12px;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  cursor: pointer;
`;

const SelectProductWrapper = styled.div`
  width: 100%;
  z-index: 10500;
  position: relative;
  height: 0;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-name: ${(props) =>
    props.enabled ? antMoveDownOut : antMoveDownIn};
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const RightContent = styled.div`
  width: 681px;
  display: flex;
  align-items: center;
`;

const CloseIcon = styled.img`
  /* position: absolute; */
  /* top: 24px; */
  /* right: 24px; */
  width: 16px;
  height: 16px;
`;

const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  background: ${commonTheme.background.black4};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px;
  padding-bottom: 0;
  z-index: 100;
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
`;

const RightHeader = styled.div`
  display: flex;
  align-items: center;
`;

const FormContainer = styled.div`
  position: relative;
  background: ${commonTheme.background.black4};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  width: 900px;
  height: 671px;
  overflow: auto;
  /* padding: 30px; */
  /* padding-bottom: 0px; */
`;

const FormWrapper = styled.div`
  width: 100%;
  padding: 30px;

  padding-top: 0px;
  padding-bottom: 0px;
`;

const CustomStyleBtn = styled.button`
  width: ${(props) => (props.width ? props.width : null)};
  height: ${(props) => (props.height ? props.height : null)};
  background: ${(props) => (props.background ? props.background : null)};
  border: ${(props) => (props.border ? props.border : "none")};
  border-color: ${(props) => (props.borderColor ? props.borderColor : "none")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0px"};
  padding: ${(props) => (props.padding ? props.padding : null)};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;
export default NumberTypeData;
