import { MenuItem } from "@mui/material";
import { Dropdown, Menu, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  deleteCouponAction,
  retrieveIssuedCouponAction,
} from "../reducers/coupon";
import { Container, Icon, Spacer } from "../styles/commonStyle";
import { commonTheme } from "../styles/theme";
import { AlertModal } from "./AlertModal";
import CreateAndModifyCouponModal from "./CreateAndModifyCouponModal";
import { CustomButton } from "./CustomButton";
import NoticeModal from "./NoticeModal";
import { Text } from "../styles/commonStyle";
import CustomSpinner from "./CustomSpinner";

/** 쿠폰 생성, 삭제를 할 수 있는 모달창을 열 수 있는 버튼이 모인 컴포넌트 */
const CouponManagement = ({
  isIssued,
  record,
  selectedRows,
  initSelectedRows,
  // jgroup_key_cookie,
}) => {
  // console.log(jgroup_key_cookie);
  const jgroupKey = useSelector(
    (state) => state.groupReducer.jgroup_key_inCookie
  );
  const [cbClicked, setCbClicked] = useState(false);
  const [dbClicked, setDbClicked] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [clickRetrievedCount, setClickRetrievedCount] = useState(0);
  const timeoutRef = useRef(null);
  const alerts = new Array(clickCount).fill(null);
  const retrievedAlerts = new Array(clickRetrievedCount).fill(null);
  const retrieveIssuedCouponLoading = useSelector(
    (state) => state.couponReducer.retrieveIssuedCouponLoading
  );
  const deleteCouponLoading = useSelector(
    (state) => state.couponReducer.deleteCouponLoading
  );
  const dispatch = useDispatch();
  /** 쿠폰 생성 모달창을 여는 함수 */
  const openCreateAndModifyModal = () => {
    document.body.style.overflow = "hidden";
    setCbClicked((cbClicked) => !cbClicked);
  };
  /** 쿠폰 생성 모달창을 닫는 함수 */
  const closeCreateAndModifyModal = () => {
    document.body.style.overflow = "unset";
    setCbClicked((cbClicked) => !cbClicked);
  };

  /** 쿠폰 삭제 모달창을 여는 함수 */
  const openNoticeModal = () => {
    if (selectedRows.length !== 0) {
      if (selectedRows.find((issuedCoupon) => issuedCoupon.jgc_deleted_dttm)) {
        handleRetrievedClick();
      } else {
        document.body.style.overflow = "hidden";
        setDbClicked((dbClicked) => !dbClicked);
      }
    } else {
      handleClick();
    }
  };
  /** 쿠폰 삭제 모달창을 닫는 함수 */
  const closeNoticeModal = () => {
    document.body.style.overflow = "unset";
    setDbClicked((dbClicked) => !dbClicked);
  };

  const handleClick = () => {
    setClickCount((prevCount) => prevCount + 1);
    setClickRetrievedCount(0);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setClickCount((prevCount) => prevCount - 1);
    }, 3000);
  };

  const handleRetrievedClick = () => {
    setClickRetrievedCount((prevCount) => prevCount + 1);
    setClickCount(0);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setClickRetrievedCount((prevCount) => prevCount - 1);
    }, 3000);
  };

  const deleteCoupon = () => {
    dispatch(
      deleteCouponAction({
        selectedRows: selectedRows,
        jgroup_key: jgroupKey,
      })
    );
    initSelectedRows();
  };

  const retrieveCoupon = () => {
    dispatch(
      retrieveIssuedCouponAction({
        selectedRows: selectedRows,
        jgroup_key: jgroupKey,
      })
    );
    initSelectedRows();
  };

  return (
    <Container>
      {(retrieveIssuedCouponLoading || deleteCouponLoading) && (
        <CustomSpinner />
      )}
      {isIssued ? (
        <React.Fragment>
          <CustomButton
            background="#4a4a4a"
            width="96px"
            height="32px"
            text="쿠폰 내용 확인"
            borderRadius="16px"
            fontSize="12px"
            onClick={openCreateAndModifyModal}
          ></CustomButton>
          <Spacer size={6} />
          <CustomButton
            width="71"
            height="32px"
            text="회수 하기"
            borderRadius="16px"
            background="#4a4a4a"
            fontSize="12px"
            onClick={openNoticeModal}
          ></CustomButton>
          {cbClicked && (
            <CreateAndModifyCouponModal
              handleChange={closeCreateAndModifyModal}
              isCheck={isIssued}
              record={record}
            />
          )}
          {dbClicked && (
            <NoticeModal
              multipleScreen={true}
              multipleButton={true}
              title="쿠폰 회수"
              secondTitle="쿠폰 회수 완료"
              headerIcon="/assets/images/icon/white/alert.png"
              secondHeaderIcon="/assets/images/icon/white/v.png"
              primaryColor="#EB5252"
              firstButtonText="취소"
              secondButtonText="회수"
              body={(() => {
                return (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text color="#000">쿠폰을 회수하시겠습니까?</Text>
                    <Spacer />
                    <Text color="#7c7c7c">쿠폰명</Text>
                    <Text color="#000">
                      {selectedRows.length !== 0 && selectedRows[0].jgc_name}
                    </Text>
                    <Spacer />
                    <Text color="#7c7c7c">고객명</Text>
                    <div style={{ overflow: "scroll" }}>
                      {selectedRows.length !== 0 &&
                        selectedRows.map((customer, index) => (
                          <div style={{ display: "inline-block" }}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {" "}
                              <Text color="#000">
                                &nbsp;&nbsp;{customer.jgjm_member_name} (
                                {customer.jgjm_member_phone_number.replace(
                                  /(\d{3})(\d{4})(\d{4})/,
                                  "$1-$2-$3"
                                )}
                                )
                              </Text>
                              {index % 2 === 0 ? (
                                <div
                                  style={{
                                    display: "block",
                                    padding: "0 24px 0 24px",
                                  }}
                                />
                              ) : null}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })()}
              secondBody={<Text color="#000">쿠폰 회수가 완료되었습니다.</Text>}
              warningText="* 고객 상세페이지 내 쿠폰 회수 내역이 기록됩니다."
              handleFirstButton={closeNoticeModal}
              handleSecondButton={retrieveCoupon}
            />
          )}
          {alerts.map((_, index) => (
            <AlertModal key={index} text="회수할 쿠폰을 선택해주세요" />
          ))}
          {retrievedAlerts.map((_, index) => (
            <AlertModal
              key={index}
              text="이미 회수된 쿠폰이 선택되어 있습니다"
            />
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CreateButton onClick={openCreateAndModifyModal}>
            <Icon
              src={
                process.env.PUBLIC_URL + "/assets/images/icon/white/plus.png"
              }
            />
            <Text color={commonTheme.font.black5}>쿠폰 생성</Text>
          </CreateButton>
          <Spacer />
          <DeleteButton onClick={openNoticeModal}>
            <Icon
              src={
                process.env.PUBLIC_URL + "/assets/images/icon/white/trash.png"
              }
            />
            <Text color={commonTheme.font.black5}>삭제</Text>
          </DeleteButton>
          {cbClicked && (
            <CreateAndModifyCouponModal
              handleChange={closeCreateAndModifyModal}
            />
          )}
          {dbClicked && (
            <NoticeModal
              multipleScreen={true}
              multipleButton={true}
              title="쿠폰 삭제"
              secondTitle="쿠폰 삭제 완료"
              headerIcon="/assets/images/icon/white/alert.png"
              secondHeaderIcon="/assets/images/icon/white/v.png"
              primaryColor="#EB5252"
              firstButtonText="취소"
              secondButtonText="삭제"
              body={(() => {
                return (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text color="#000">
                      <Text color="#000" fontFamily="Noto Sans KR-Bold">
                        [
                        {selectedRows.length !== 0 && selectedRows[0].jgcp_name}
                        ]
                      </Text>
                      {selectedRows.length !== 1 ? (
                        <>
                          &nbsp;외&nbsp;
                          <Text color="#EB5252" fontFamily="Noto Sans KR-Bold">
                            {selectedRows.length - 1}개
                          </Text>
                          의&nbsp;
                        </>
                      ) : null}
                      &nbsp;쿠폰을 정말로 삭제하시겠습니까?
                    </Text>
                    <Spacer size={3} />
                    <Text color="#EB5252" fontFamily="Noto Sans KR-Bold">
                      (이미 발행된 쿠폰은 삭제되지 않습니다)
                    </Text>
                  </div>
                );
              })()}
              secondBody={<Text color="#000">쿠폰 삭제가 완료되었습니다.</Text>}
              warningText="* 삭제 후 복구가 불가합니다."
              secondWarningText="* 삭제된 쿠폰은 복구가 불가합니다."
              handleFirstButton={closeNoticeModal}
              handleSecondButton={deleteCoupon}
            />
          )}
          {alerts.map((_, index) => (
            <AlertModal key={index} text="삭제할 쿠폰을 선택해주세요" />
          ))}
        </React.Fragment>
      )}
    </Container>
  );
};

const CreateButton = styled.button`
  width: 103px;
  height: 32px;
  background: ${commonTheme.crm.brown5};
  border: none;
  border-radius: 3px;
  padding: 7px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled.button`
  width: 71px;
  height: 32px;
  background: ${commonTheme.background.black7};
  border: none;
  border-radius: 3px;
  padding: 7px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

export default CouponManagement;
