import { Dropdown, Layout, Menu, Select } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  getIssuedCouponAction,
  updateFilteredCouponAction,
  updateFilteredIssuedCouponAction,
  updateIsUseCouponAction,
} from "../reducers/coupon";
import {
  CloseButton,
  Icon,
  ModalScreen,
  PositionController,
  Spacer,
  Text,
  Title,
} from "../styles/commonStyle";
import { commonTheme } from "../styles/theme";
import CouponManagement from "./CouponManagement";
import CouponSearchBar from "./CouponSearchBar";
import CouponTable from "./CouponTable";
import { CustomButton } from "./CustomButton";
import CustomSpinner from "./CustomSpinner";

const MenuItem = Menu.Item;

const menuStyle = {
  backgroundColor: "#4a4a4a",
};

const menuItemStyle = {
  background: "none",
  color: "#ffffff",
  fontSize: "12px",
};
const CustomMenu = ({ handleChange }) => {
  return (
    <Menu style={menuStyle} defaultSelectedKeys={1} onChange={handleChange}>
      <MenuItem key={1} style={menuItemStyle} onClick={handleChange}>
        사용 여부 전체
      </MenuItem>
      <MenuItem key={2} style={menuItemStyle} onClick={handleChange}>
        사용
      </MenuItem>
      <MenuItem key={3} style={menuItemStyle} onClick={handleChange}>
        미사용
      </MenuItem>
    </Menu>
  );
};

export const CouponHistory = ({ handleChange, record }) => {
  const jgroupKey = useSelector(
    (state) => state.groupReducer.jgroup_key_inCookie
  );
  const [item, setItem] = useState("1");
  const [isHistoryTable, setIsHistoryTable] = useState(false);
  const [isIssued, setIsIssued] = useState(true);
  const [stop, setStop] = useState(false);
  const [filter, setFilter] = useState(null);
  const issuedCouponList = useSelector(
    (state) => state.couponReducer.issuedCouponList
  );
  const [filteredIssuedCouponList, setFilteredIssuedCouponList] =
    useState(null);
  const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  const handleHistoryTable = () => {
    setIsHistoryTable(true);
  };

  const handleMenuItem = (e) => {
    setItem(e.key);
  };

  const handleSelectedRows = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const handleSelectedRowsKeys = (selectedRowsKeys) => {
    setSelectedRowsKeys(selectedRowsKeys);
  };

  const initSelectedRows = () => {
    handleSelectedRows([]);
    handleSelectedRowsKeys([]);
  };

  useEffect(() => {
    dispatch(
      getIssuedCouponAction({
        jgcp_key: record.jgcp_key,
        jgroup_key: jgroupKey,
      })
    );
    setFilteredIssuedCouponList(issuedCouponList);
    handleHistoryTable();
  }, []);

  useEffect(() => {
    if (item === "1") {
      if (filter) {
        setFilteredIssuedCouponList(
          issuedCouponList.filter((coupon) =>
            coupon.jgjm_member_name.includes(filter)
          )
        );
      } else {
        setFilteredIssuedCouponList(issuedCouponList);
      }
    } else if (item === "2") {
      if (filter) {
        setFilteredIssuedCouponList(
          issuedCouponList
            .filter((coupon) => (coupon.jgc_is_use === true ? coupon : null))
            .filter((coupon) => coupon.jgjm_member_name.includes(filter))
        );
      } else {
        setFilteredIssuedCouponList(
          issuedCouponList.filter((coupon) =>
            coupon.jgc_is_use === true ? coupon : null
          )
        );
      }
    } else {
      if (filter) {
        setFilteredIssuedCouponList(
          issuedCouponList
            .filter((coupon) => (coupon.jgc_is_use === false ? coupon : null))
            .filter((coupon) => coupon.jgjm_member_name.includes(filter))
        );
      } else {
        setFilteredIssuedCouponList(
          issuedCouponList.filter((coupon) =>
            coupon.jgc_is_use === false ? coupon : null
          )
        );
      }
    }
  }, [item, filter, issuedCouponList]);
  if (!issuedCouponList) return <CustomSpinner />;
  return (
    <ModalScreen>
      <PositionController>
        <ModalContainer stop={stop}>
          <div style={{ padding: "0" }}>
            <Layout
              style={{
                maxHeight: "671px",
                padding: "24px",
                background: "#2c2c2c",
              }}
            >
              <Header
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  minHeight: "92px",
                }}
              >
                {/* <CouponSearchBar dataSource={dataSource} />
        <CouponHistoryManagement dataSource={dataSource} /> */}
                <Title>[{record.jgcp_name}] 발행 내역</Title>
                <CloseButton type="button" onClick={handleChange}>
                  <CloseIcon
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icon/white/x.png"
                    }
                  />
                </CloseButton>
                <Spacer size={11} />
                <IssuedCouponManagementContainer>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Dropdown
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      popupStyle={{ zIndex: 10500 }}
                      trigger={"click"}
                      overlay={<CustomMenu handleChange={handleMenuItem} />}
                      // position="relative"
                      overlayStyle={{ position: "absolute" }}
                    >
                      <div
                        style={{
                          background: "#4a4a4a",
                          padding: "12px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "141px",
                          justifyContent: "space-between",

                          height: "32px",
                          borderRadius: "3px",
                        }}
                      >
                        <Text color="#fff" size={12}>
                          {item === "1"
                            ? "사용 여부 전체"
                            : item === "2"
                            ? "사용"
                            : "미사용"}
                        </Text>
                        <Icon
                          width="12px"
                          height="6px"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icon/select/toggle_down.png"
                          }
                        />
                      </div>
                    </Dropdown>
                    <Spacer />

                    <CouponSearchBar
                      isIssued={isIssued}
                      filteredIssuedCouponList={filteredIssuedCouponList}
                      setFilteredIssuedCouponList={setFilteredIssuedCouponList}
                      setFilter={setFilter}
                      issuedCouponList={issuedCouponList}
                    />
                  </div>

                  {isIssued && (
                    <CouponManagement
                      isIssued={isIssued}
                      record={record}
                      selectedRows={selectedRows}
                      initSelectedRows={initSelectedRows}
                    />
                  )}
                </IssuedCouponManagementContainer>
              </Header>

              <Content>
                {isHistoryTable && (
                  <CouponTable
                    isHistoryTable={isHistoryTable}
                    filteredIssuedCouponList={filteredIssuedCouponList}
                    issuedCouponList={issuedCouponList}
                    handleSelectedRowsKeys={handleSelectedRowsKeys}
                    handleSelectedRows={handleSelectedRows}
                    selectedRowsKeys={selectedRowsKeys}
                  />
                )}
              </Content>
              <Spacer size={28} />
              <CustomButton
                width="900px"
                position="absolute"
                left="0"
                bottom="0"
                background="#fa6400"
                onClick={handleChange}
                text="확인"
                zIndex={10000}
              />
            </Layout>
          </div>
        </ModalContainer>
      </PositionController>
    </ModalScreen>
  );
};

const IssuedCouponManagementContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalContainer = styled.div`
  position: relative;
  background: ${commonTheme.background.black4};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  width: 900px;
  height: 671px;
  overflow: ${(props) => (props.stop ? "hidden" : "auto")};
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
`;
