import { Button, Table } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSelectedRowAction,
  updateSelectedIssuedCouponAction,
} from "../reducers/coupon";
import { columns, historyColumns } from "./CouponTableColumnData";

/** 쿠폰 테이블입니다 */
const CouponTable = ({
  isHistoryTable,
  filteredCouponList,
  filteredIssuedCouponList,
  selectedRowsKeys,
  handleSelectedRows,
  handleSelectedRowsKeys,
  height,
}) => {
  const [selectionType, setSelectionType] = useState("checkbox");
  /** 선택된 행들에 대한 정보를 action을 통해 redux의 selectedRow State에 저장 */
  const rowSelection = {
    selectedRowKeys: selectedRowsKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      handleSelectedRows(selectedRows);
      handleSelectedRowsKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // CustomColumn configuration not to be checked
      name: record.name,
    }),
  };

  /** row의 key를 record(couponList 배열의 요소).key로 unique하게 만듭니다 */
  const rowKey = (record, index) => {
    return record.jgcp_key;
  };

  const historyRowKey = (record, index) => {
    return record.jgc_key;
  };

  return (
    /**
     * @todo api로부터 50개의 데이터를 받아서, 출력을 하고 이후 스크롤을 내리면서 scroll-y에 닿으면 api로부터 50개의 데이터를 받아서 보여주도록 무한 스크롤로 구현을 해야됨.
     */
    <React.Fragment>
      {isHistoryTable && filteredIssuedCouponList ? (
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          scroll={{ y: 430 }}
          dataSource={filteredIssuedCouponList}
          columns={historyColumns}
          pagination={false}
          rowKey={historyRowKey}
          rowHeight={42}
        />
      ) : filteredCouponList ? (
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          scroll={{ y: height }}
          dataSource={filteredCouponList}
          columns={columns}
          pagination={false}
          rowKey={rowKey}
          rowHeight={42}
        />
      ) : null}
    </React.Fragment>
  );
};

export default CouponTable;
