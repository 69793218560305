import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import * as couponAPI from "../api/couponAPI";
import {
  CREATE_COUPON,
  CREATE_COUPON_FAILURE,
  CREATE_COUPON_SUCCESS,
  DELETE_COUPON,
  DELETE_COUPON_FAILURE,
  DELETE_COUPON_SUCCESS,
  GET_COUPON,
  GET_COUPON_FAILURE,
  GET_COUPON_SUCCESS,
  GET_CRM_PRODUCT,
  GET_CRM_PRODUCT_FAILURE,
  GET_CRM_PRODUCT_SUCCESS,
  GET_GOODS_BY_KEYS,
  GET_GOODS_BY_KEYS_FAILURE,
  GET_GOODS_BY_KEYS_SUCCESS,
  GET_ISSUED_COUPON,
  GET_ISSUED_COUPON_FAILURE,
  GET_ISSUED_COUPON_SUCCESS,
  GET_MARKET_PRODUCT,
  GET_MARKET_PRODUCT_FAILURE,
  GET_MARKET_PRODUCT_SUCCESS,
  GET_MEMBER_COUPON,
  GET_MEMBER_COUPON_FAILURE,
  GET_MEMBER_COUPON_SUCCESS,
  MODIFY_COUPON,
  MODIFY_COUPON_FAILURE,
  MODIFY_COUPON_SUCCESS,
  RETRIEVE_ISSUED_COUPON,
  RETRIEVE_ISSUED_COUPON_FAILURE,
  RETRIEVE_ISSUED_COUPON_SUCCESS,
  RETRIEVE_MEMBER_COUPON,
  RETRIEVE_MEMBER_COUPON_FAILURE,
  RETRIEVE_MEMBER_COUPON_SUCCESS,
} from "../reducers/coupon";
export default function* couponSaga() {
  /** 쿠폰 리스트를 가져오는 함수 */
  function* getCoupon(action) {
    try {
      const result = yield call(couponAPI.getCoupon, action.data);
      yield put({
        type: GET_COUPON_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_COUPON_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetCoupon() {
    yield takeLatest(GET_COUPON, getCoupon);
  }
  /** 쿠폰을 생성하는 함수
   * @param action payload로 받은 데이터
   */
  function* createCoupon(action) {
    try {
      const result = yield call(couponAPI.createCoupon, action.data);
      yield put({
        type: CREATE_COUPON_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: CREATE_COUPON_FAILURE,
        data: err,
      });
    }
  }
  function* watchCreateCoupon() {
    yield takeLatest(CREATE_COUPON, createCoupon);
  }
  /** 쿠폰을 수정하는 함수
   * @param action payload로 받은 데이터
   */
  function* modifyCoupon(action) {
    try {
      const result = yield call(couponAPI.modifyCoupon, action.data);
      yield put({
        type: MODIFY_COUPON_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: MODIFY_COUPON_FAILURE,
        data: err,
      });
    }
  }
  function* watchModifyCoupon() {
    yield takeLatest(MODIFY_COUPON, modifyCoupon);
  }
  /** 쿠폰을 삭제하는 함수
   * @param action payload로 받은 데이터
   */
  function* deleteCoupon(action) {
    try {
      const result = yield call(couponAPI.deleteCoupon, action.data);
      yield put({
        type: DELETE_COUPON_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: DELETE_COUPON_FAILURE,
        data: err,
      });
    }
  }
  function* watchDeleteCoupon() {
    yield takeLatest(DELETE_COUPON, deleteCoupon);
  }

  function* getMemberCoupon(action) {
    try {
      const result = yield call(couponAPI.getMemberCoupon, {
        groupKey: savedJgroupKey ?? action.data,
        BROJ_SERVER,
      });
      yield put({
        type: GET_MEMBER_COUPON_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_MEMBER_COUPON_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetMemberCoupon() {
    yield takeLatest(GET_MEMBER_COUPON, getMemberCoupon);
  }
  /** 쿠폰 발행 내역을 가져오는 함수
   * @param action payload로 받은 데이터
   */
  function* getIssuedCoupon(action) {
    try {
      const result = yield call(couponAPI.getIssuedCoupon, action.data);
      yield put({
        type: GET_ISSUED_COUPON_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_ISSUED_COUPON_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetIssuedCoupon() {
    yield takeLatest(GET_ISSUED_COUPON, getIssuedCoupon);
  }
  /** 발행된 쿠폰을 회수하는 함수
   * @param action payload로 받은 쿠폰을 회수할 사람들의 리스트
   */
  function* retrieveIssuedCoupon(action) {
    try {
      let keys = action.data.selectedRows.map((data) => data.jgc_key);

      const result = yield call(couponAPI.retrieveIssuedCoupon, {
        keys: keys,
        data: action.data,
      });
      yield put({
        type: RETRIEVE_ISSUED_COUPON_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: RETRIEVE_ISSUED_COUPON_FAILURE,
        data: err,
      });
    }
  }
  function* watchRetrieveIssuedCoupon() {
    yield takeLatest(RETRIEVE_ISSUED_COUPON, retrieveIssuedCoupon);
  }

  /**
   * CRM의 상품을 가져오는 함수
   * @param data 상품키
   * @deprecated 고도화때 쓸거에요
   */
  function* getCrmProduct(action) {
    try {
      const result = yield call(couponAPI.getCrmProduct, action.data);
      yield put({
        type: GET_CRM_PRODUCT_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_CRM_PRODUCT_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetCrmProduct() {
    yield takeLatest(GET_CRM_PRODUCT, getCrmProduct);
  }
  /** 마켓 상품을 가져오는 함수
   * @param action payload로 받은 J-Group Key
   */
  function* getMarketProduct(action) {
    try {
      const result = yield call(couponAPI.getMarketProduct, action.data);
      yield put({
        type: GET_MARKET_PRODUCT_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_MARKET_PRODUCT_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetMarketProduct() {
    yield takeLatest(GET_MARKET_PRODUCT, getMarketProduct);
  }
  /** 굿즈키로 굿즈들의 정보를 가져오는 함수
   * @param action payload로 받은 Goods_Keys 배열
   */
  function* getGoodsByKeys(action) {
    try {
      const result = yield call(
        couponAPI.getGoodsByKeys,
        action.data.join(",")
      );
      yield put({
        type: GET_GOODS_BY_KEYS_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_GOODS_BY_KEYS_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetGoodsByKeys() {
    yield takeLatest(GET_GOODS_BY_KEYS, getGoodsByKeys);
  }

  yield all([
    fork(watchGetCoupon),
    fork(watchCreateCoupon),
    fork(watchModifyCoupon),
    fork(watchDeleteCoupon),
    fork(watchGetMemberCoupon),
    fork(watchGetIssuedCoupon),
    fork(watchRetrieveIssuedCoupon),
    fork(watchGetCrmProduct),
    fork(watchGetMarketProduct),
    fork(watchGetGoodsByKeys),
  ]);
}
