import produce from "immer";

/* ACTION 정의 */
export const GET_AUTOMESSAGE = "GET_AUTOMESSAGE";
export const GET_AUTOMESSAGE_SUCCES = "GET_AUTOMESSAGE_SUCCES";
export const GET_AUTOMESSAGE_FAILURE = "GET_AUTOMESSAGE_FAILURE";

export const GET_CERTIFICATEDATA = "GET_CERTIFICATEDATA";
export const GET_CERTIFICATEDATA_SUCCES = "GET_CERTIFICATEDATA_SUCCES";
export const GET_CERTIFICATEDATA_FAILURE = "GET_CERTIFICATEDATA_FAILURE";

export const GET_MESSAGE_HISTORY = "GET_MESSAGE_HISTORY";
export const GET_MESSAGE_HISTORY_SUCCESS = "GET_MESSAGE_HISTORY_SUCCESS";
export const GET_MESSAGE_HISTORY_FAILURE = "GET_MESSAGE_HISTORY_FAILURE";

export const GET_PUSH_NOTIFICATION_HISTORY = "GET_PUSH_NOTIFICATION_HISTORY";
export const GET_PUSH_NOTIFICATION_HISTORY_SUCCESS =
  "GET_PUSH_NOTIFICATION_HISTORY_SUCCESS";
export const GET_PUSH_NOTIFICATION_HISTORY_FAILURE =
  "GET_PUSH_NOTIFICATION_HISTORY_FAILURE";

export const MODIFY_AUTOMESSAGE = "MODIFY_AUTOMESSAGE";
export const MODIFY_AUTOMESSAGE_SUCCESS = "MODIFY_AUTOMESSAGE_SUCCESS";
export const MODIFY_AUTOMESSAGE_FAILURE = "MODIFY_AUTOMESSAGE_FAILURE";

export const DELETE_AUTOMESSAGE = "DELETE_AUTOMESSAGE";
export const DELETE_AUTOMESSAGE_SUCCESS = "DELETE_AUTOMESSAGE_SUCCESS";
export const DELETE_AUTOMESSAGE_FAILURE = "DELETE_AUTOMESSAGE_FAILURE";

export const CREATE_AUTOMESSAGE = "CREATE_AUTOMESSAGE";
export const CREATE_AUTOMESSAGE_SUCCESS = "CREATE_AUTOMESSAGE_SUCCESS";
export const CREATE_AUTOMESSAGE_FAILURE = "CREATE_AUTOMESSAGE_FAILURE";

/*ACTION 등록 */
/*인증된 data 가져오는 api */
export const getCertificateDataAction = (data) => ({
  type: GET_CERTIFICATEDATA,
  data,
});

/* 자동알림 설정 가져오는 Action */
export const getAutoMessageSettingAction = (data) => ({
  type: GET_AUTOMESSAGE,
  data,
});

/** 메세지 내역을 불러오는 Action */
export const getMessageHistoryAction = (data) => {
  return {
    type: GET_MESSAGE_HISTORY,
    data,
  };
};

/** 푸시 알림 내역을 불러오는 Action */
export const getPushNotificationHistoryAction = (data) => {
  return {
    type: GET_PUSH_NOTIFICATION_HISTORY,
    data,
  };
};

/* 자동알림 설정 수정 ACTION */
export const modifyAutoMessageSettigAction = (data) => ({
  type: MODIFY_AUTOMESSAGE,
  data,
});

/** 자동문자 설정 삭제 Action */
export const deleteAutoMessageSettingAction = (data) => ({
  type: DELETE_AUTOMESSAGE,
  data,
});

export const createAutoMessageAction = (data) => ({
  type: CREATE_AUTOMESSAGE,
  data,
});

export const initialState = {
  getCertificateDataLoading: false,
  getCertificateDataDone: false,
  getCertificateDataError: false,
  certificateData: [],

  getAutonMessageSettingLoading: false,
  getAutoMessageSettingDone: false,
  getAutoMessageSettingError: false,
  customerRelativeSettingList: [],
  productRelativeSettingList: [],

  getMessageHistoryLoading: false,
  getMessageHistoryDone: false,
  getMessageHistoryError: false,

  getPushNotificationHistoryLoading: false,
  getPushNotificationHistoryDone: false,
  getPushNotificationHistoryError: false,

  smsList: null,
  lmsList: null,
  mmsList: null,
  pushNotificationList: null,
  messageList: null,

  modifyAutoMessageLoading: false,
  modifyAutoMessageDone: false,
  modifyAutoMessageError: false,

  deleteAutoMessageLoading: false,
  deleteAutoMessageDone: false,
  deleteAutoMessageError: false,

  createAutoMessageLoading: false,
  createAutoMessageDone: false,
  createAutoMessageError: false,
};

const messageReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_CERTIFICATEDATA:
        draft.getCertificateDataDone = false;
        draft.getCertificateDataError = null;
        draft.getCertificateDataLoading = true;
        break;
      case GET_CERTIFICATEDATA_SUCCES:
        /* 승인된 번호만 필터링 */
        let _certificateData = action.data.filter((data) => {
          return data.status === 1;
        });
        draft.certificateData = _certificateData;
        draft.getCertificateDataDone = true;
        draft.getCertificateDataError = false;
        break;
      case GET_CERTIFICATEDATA_FAILURE:
        draft.getCertificateDataError = action.error;
        draft.getCertificateDataLoading = false;
        break;

      case GET_AUTOMESSAGE:
        draft.getAutoMessageSettingDone = false;
        draft.getAutoMessageSettingError = null;
        draft.getAutonMessageSettingLoading = true;
        break;
      case GET_AUTOMESSAGE_SUCCES:
        /* 고객 관련 필터링 */
        draft.customerRelativeSettingList = action.data.filter(
          (data) =>
            data.function_type === "CUSTOMER_REBUY_TICKET_ALARM" ||
            data.function_type === "CUSTOMER_BIRTHDAY_ALARM" ||
            data.function_type === "CUSTOMER_NON_ATTENDANCE" ||
            data.function_type === "CUSTOMER_FIRST_BUY_TICKET_ALARM" ||
            data.function_type === "NEW_CUSTOMER_CREATED" ||
            data.function_type === "CUSTOMER_BUY_RENTAL_TICKET_ALARM" ||
            data.function_type === "CUSTOMER_COUPON_EXPIRE_ALARM" ||
            data.function_type === "CUSTOMER_CONTRACT_DONE_ALARM" ||
            data.function_type === "ACTIVE_CUSTOMER_ALARM" ||
            data.function_type === "INACTIVE_CUSTOMER_ALARM"
        );
        draft.customerRelativeSettingList =
          draft.customerRelativeSettingList.map((data) => {
            return { ...data, related: "customer" };
          });

        /* 상품 관련 필터링 */
        draft.productRelativeSettingList = action.data.filter(
          (data) =>
            data.function_type === "CUSTOMER_HOLDING_NEW_ALARM" ||
            data.function_type === "CUSTOMER_HOLDING_EXPIRE_ALARM" ||
            data.function_type === "MEMBER_TICKET_EXPIRE_ALARM" ||
            data.function_type === "LESSON_TICKET_EXPIRE_ALARM" ||
            data.function_type === "LOCKER_EXPIRE_ALARM" ||
            data.function_type === "SPORTS_EXPIRE_ALARM" ||
            data.function_type === "MEMBER_TICKET_EXPIRE_DATE_ALARM" ||
            data.function_type === "LESSON_TICKET_EXPIRE_DATE_ALARM"
        );
        draft.productRelativeSettingList = draft.productRelativeSettingList.map(
          (data) => {
            {
              return { ...data, related: "product" };
            }
          }
        );

        // console.log(
        //   "customerRelativeSettingList",
        //   draft.customerRelativeSettingList
        // );
        // console.log("product", draft.productRelativeSettingList);
        draft.getAutoMessageSettingDone = true;
        draft.getAutoMessageSettingError = false;
        break;
      case GET_AUTOMESSAGE_FAILURE:
        draft.getAutoMessageSettingError = action.error;
        draft.getAutonMessageSettingLoading = false;
        break;
      case GET_MESSAGE_HISTORY:
        draft.messageList = [];
        draft.lmsList = [];
        draft.smsList = [];
        draft.getMessageHistoryDone = false;
        draft.getMessageHistoryError = null;
        draft.getMessageHistoryLoading = true;
        break;
      case GET_MESSAGE_HISTORY_SUCCESS:
        // draft.mmsList = action.data.mms.map((mms) =>
        //   Object.assign(mms, { TYPE: "MMS" })
        // );
        // draft.lmsList = action.data.sms.filter((lms) =>
        //   calculateGreaterthan(lms, lms.TR_MSG, 90)
        // );
        // draft.smsList = action.data.sms.filter((sms) =>
        //   calculateLessthan(sms, sms.TR_MSG, 90)
        // );

        draft.lmsList = action.data.lms.sort((a, b) => {
          const dateA = new Date(a.RSLTDATE);
          const dateB = new Date(b.RSLTDATE);
          return dateB - dateA;
        });
        draft.smsList = action.data.sms.sort((a, b) => {
          const dateA = new Date(a.TR_RSLTDATE);
          const dateB = new Date(b.TR_RSLTDATE);
          return dateB - dateA;
        });
        draft.messageList = [
          ...draft.smsList,
          ...draft.lmsList,
          // ...draft.mmsList,
        ];
        draft.messageList = draft.messageList.sort((a, b) => {
          const dateA = new Date(a.RSLTDATE || a.TR_RSLTDATE);
          const dateB = new Date(b.RSLTDATE || b.TR_RSLTDATE);
          return dateB - dateA;
        });
        draft.getMessageHistoryDone = true;
        draft.getMessageHistoryError = false;
        draft.getMessageHistoryLoading = false;
        break;
      case GET_MESSAGE_HISTORY_FAILURE:
        draft.getMessageHistoryError = action.error;
        draft.getMessageHistoryLoading = false;
        break;
      case GET_PUSH_NOTIFICATION_HISTORY:
        draft.getPushNotificationHistoryLoading = true;
        draft.getPushNotificationHistoryDone = null;
        draft.getPushNotificationHistoryError = true;
        break;
      case GET_PUSH_NOTIFICATION_HISTORY_SUCCESS:
        draft.pushNotificationList = action.data;

        draft.getPushNotificationHistoryLoading = false;
        draft.getPushNotificationHistoryDone = true;
        draft.getPushNotificationHistoryError = false;
        break;
      case GET_PUSH_NOTIFICATION_HISTORY_FAILURE:
        draft.getPushNotificationHistoryError = action.error;
        draft.getPushNotificationHistoryLoading = false;
        break;

      case MODIFY_AUTOMESSAGE:
        draft.createAutoMessageDone = false;
        draft.modifyAutoMessageDone = false;
        draft.modifyAutoMessageError = null;
        draft.modifyAutoMessageLoading = true;
        break;
      case MODIFY_AUTOMESSAGE_SUCCESS:
        draft.modifyAutoMessageDone = true;
        draft.modifyAutoMessageError = false;
        draft.modifyAutoMessageLoading = false;
        break;
      case MODIFY_AUTOMESSAGE_FAILURE:
        draft.modifyAutoMessageError = action.error;
        draft.modifyAutoMessageLoading = false;
        break;

      case DELETE_AUTOMESSAGE:
        draft.deleteAutoMessageLoading = true;
        draft.deleteAutoMessageDone = false;
        draft.deleteAutoMessageError = false;
        break;
      case DELETE_AUTOMESSAGE_SUCCESS:
        draft.deleteAutoMessageLoading = false;
        draft.deleteAutoMessageDone = true;

        draft.customerRelativeSettingList =
          state.customerRelativeSettingList.filter(
            (data) => !(data.auto_message_key === action.data.auto_message_key)
          );

        draft.productRelativeSettingList =
          state.productRelativeSettingList.filter(
            (data) => !(data.auto_message_key === action.data.auto_message_key)
          );
        break;
      case DELETE_AUTOMESSAGE_FAILURE:
        draft.deleteAutoMessageLoading = false;
        draft.deleteAutoMessageError = true;
        break;

      case CREATE_AUTOMESSAGE:
        draft.modifyAutoMessageDone = false;
        draft.createAutoMessageLoading = true;
        draft.createAutoMessageDone = false;
        draft.createAutoMessageError = false;
        break;
      case CREATE_AUTOMESSAGE_SUCCESS:
        draft.createAutoMessageLoading = false;
        draft.createAutoMessageDone = true;
        break;
      case CREATE_AUTOMESSAGE_FAILURE:
        draft.createAutoMessageLoading = false;
        draft.createAutoMessageError = true;
        break;
      default:
        break;
    }
  });
};
export default messageReducer;
