import { Layout } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  CloseButton,
  Icon,
  ModalScreen,
  PositionController,
  Spacer,
  Text,
  Title,
} from "../styles/commonStyle";
import { commonTheme } from "../styles/theme";
import { CustomButton } from "./CustomButton";

const MessageInfoNoticeModal = ({ handleChange }) => {
  const guideRef = useRef();

  const downloadGuide = () => {
    const pdfUrl = "https://www.wingo.co.kr/file/spam"; // 다운로드할 PDF 파일 경로
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const fileUrl = URL.createObjectURL(blob); // 다운로드 URL 생성
        const downloadLink = document.createElement("a");
        downloadLink.href = fileUrl;
        downloadLink.download = "kisa_spam_guide.pdf"; // 다운로드할 파일 이름
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  };
  return (
    <ModalScreen zIndex={100000}>
      <PositionController>
        <ModalContainer>
          <Layout
            style={{
              height: "671px",
              padding: "24px",
              background: "#2c2c2c",
            }}
          >
            <Header
              style={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title>광고성 정보 전송 시 유의사항</Title>
              <CloseButton type="button" onClick={handleChange}>
                <CloseIcon
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icon/white/x.png"
                  }
                />
              </CloseButton>
            </Header>
            <Content>
              <Text style={{ display: "block" }}>
                한국인터넷진흥원(KISA)에서 배포한{" "}
                <Text color="#FA943D">
                  <ins
                    ref={guideRef}
                    onClick={downloadGuide}
                    style={{ cursor: "pointer" }}
                  >
                    불법 스팸 방지를 위한 정보통신망법 안내서
                  </ins>
                </Text>
                에 따라
              </Text>
              <Text>
                {" "}
                광고성 정보를 전송하실 경우 아래 규칙을 준수하여 메시지를
                작성하셔야 합니다.
              </Text>
              <Spacer />
              <Text color="#EB5252">
                광고표시 미준수 시 메시지 발송이 중단될 수 있으며 KISA로부터
                과태료 등의 처벌을 받을 수 있습니다.
              </Text>
              <Spacer />
              <Icon
                src={process.env.PUBLIC_URL + `./assets/images/adInfo.png`}
                style={{ width: "100%", height: "calc(100% - 150px)" }}
              />
            </Content>
            <CustomButton
              width="900px"
              position="absolute"
              left="0"
              bottom="0"
              background="#fa6400"
              onClick={handleChange}
              text="확인"
              zIndex={10000}
            />
          </Layout>
        </ModalContainer>
      </PositionController>
    </ModalScreen>
  );
};

const ModalContainer = styled.div`
  position: relative;
  background: ${commonTheme.background.black7};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  width: 900px;
  height: 671px;
  overflow: ${(props) => (props.stop ? "hidden" : "auto")};
`;
const CloseIcon = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
`;

export default MessageInfoNoticeModal;
