import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import * as groupAPI from "../api/groupAPI";
import {
  GET_JGROUP,
  GET_JGROUP_SUCCESS,
  GET_JGROUP_FAILURE,
} from "../reducers/group";
export default function* groupSaga() {
  function* getJgroup(action) {
    try {
      const result = yield call(groupAPI.getJgroup, action.data);
      yield put({
        type: GET_JGROUP_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_JGROUP_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetJgroup() {
    yield takeLatest(GET_JGROUP, getJgroup);
  }

  yield all([fork(watchGetJgroup)]);
}
