import React, { useState } from "react";
import styled from "styled-components";
import {
  CloseButton,
  ModalScreen,
  PositionController,
  Spacer,
  Text,
} from "../styles/commonStyle";
import { CustomButton } from "./CustomButton";

const MessagePreviewModal = ({ message, handleChange, isCoupon }) => {
  return (
    <ModalScreen zIndex={20000}>
      <PositionController>
        <PreviewContainer>
          <PreviewHeader>
            <Text color={"#fff"} size={18} fontFamily="Noto Sans KR-Bold">
              {isCoupon ? "쿠폰 설명 확인" : "메시지 내용 확인"}
            </Text>
            <CloseButton type="button" onClick={handleChange}>
              <CloseIcon
                src={process.env.PUBLIC_URL + "/assets/images/icon/white/x.png"}
              />
            </CloseButton>
          </PreviewHeader>
          <Spacer size={12} />

          <PreviewWrapper>
            <PreviewContent>
              <Text size={14} style={{ whiteSpace: "pre-line" }}>
                {message}
              </Text>
            </PreviewContent>
          </PreviewWrapper>
          <Spacer size={12} />
          <CustomButton
            position="absolute"
            left="0"
            bottom="0"
            borderRadius="0 0 8px 8px"
            background="#fa6400"
            text="확인"
            onClick={handleChange}
          />
        </PreviewContainer>
      </PositionController>
    </ModalScreen>
  );
};

const PreviewContainer = styled.div`
  width: 450px;
  height: 500px;
  padding: 24px;
  background: #2c2c2c;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  position: relative;
  text-align: start;
`;

const PreviewHeader = styled.div`
  height: 24px;
`;

const PreviewWrapper = styled.div`
  width: 402px;
  height: 345px;
  background: #4a4a4a;
  border-radius: 10px;
  padding: 12px;
`;

const PreviewContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledBar = styled.div`
  width: 68px;
  height: 1px;
  border: 4px solid #e7ebee;
  border-radius: 5px;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
`;

const PreviewCoupon = styled.div`
  width: 320px;
  height: 160px;
  background: #22d8b3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
`;

const CouponName = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  color: #ffffff;
  padding-left: 25px;
  padding-top: 27px;
`;

const ExpirationDate = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #ffffff;
  padding-left: 25px;
`;

const DiscountType = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 61px;

  color: #ffffff;
  right: 24px;
  bottom: 7px;
  position: absolute;
`;

export default MessagePreviewModal;
