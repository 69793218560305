import { Input } from "antd";
import React from "react";
import styled from "styled-components";

const CustomInput = ({
  width,
  height,
  borderRadius,
  background,
  border,
  color,
  placeholder,
  suffix,
  proffix,
  onChange,
  autoComplete,
  defaultValue,
  textAlign,
  rule,
  maxLength,
  value,
  type,
}) => {
  return type === "textArea" ? (
    <CustomTextArea
      width={width}
      height={height}
      border_radius={borderRadius}
      background={background}
      bordered={border}
      color={color}
      placeholder={placeholder}
      suffix={suffix}
      proffix={proffix}
      onChange={onChange}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      text_align={textAlign}
      rule={rule}
      maxLength={maxLength}
      value={value}
    />
  ) : (
    <CustomIpt
      width={width}
      height={height}
      border_radius={borderRadius}
      background={background}
      bordered={border}
      color={color}
      placeholder={placeholder}
      suffix={suffix}
      proffix={proffix}
      onChange={onChange}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      text_align={textAlign}
      rule={rule}
      maxLength={maxLength}
      value={value}
    />
  );
};

const CustomIpt = styled(Input)`
  width: ${(props) => (props.width ? `${props.width}` : "681px")};
  height: ${(props) => (props.height ? `${props.height}` : "45px")};
  border-radius: ${(props) =>
    props.border_radius ? `${props.border_radius}` : "5px"};
  background: ${(props) =>
    props.background ? `${props.background}` : "#4a4a4a"};
  border: ${(props) => (props.bordered ? `${props.bordered}` : "none")};
  color: ${(props) => (props.color ? `${props.color}` : "#fff")};
  text-align: ${(props) =>
    props.text_align ? `${props.text_align}` : "start"};
`;

const CustomTextArea = styled(Input.TextArea)`
  max-width: ${(props) =>
    props.width ? `${props.width} !important` : "681px !important"};
  width: ${(props) =>
    props.width ? `${props.width} !important` : "681px !important"};
  height: ${(props) =>
    props.height ? `${props.height} !important` : "45px !important"};
  border-radius: ${(props) =>
    props.border_radius
      ? `${props.border_radius} !important`
      : "5px !important"};
  background: ${(props) =>
    props.background ? `${props.background} !important` : "#4a4a4a !important"};
  border: ${(props) =>
    props.bordered ? `${props.bordered} !important` : "none"};
  color: ${(props) =>
    props.color ? `${props.color} !important` : "#fff !important"};
  text-align: ${(props) =>
    props.text_align ? `${props.text_align} !important` : "start !important"};
`;

export default CustomInput;
