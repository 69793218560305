export const validateRate = (rule, value) => {
  if (!value) {
    return Promise.reject("할인율을 입력해주세요");
  } else if (isNaN(value)) {
    return Promise.reject("유효한 숫자를 입력해주세요");
  } else if (value < 0 || value > 100) {
    return Promise.reject("0~100까지의 숫자만 입력 가능합니다");
  } else {
    return Promise.resolve();
  }
};

export const validatePrice = (rule, value) => {
  if (!value) {
    return Promise.reject("할인액을 입력해주세요");
  } else if (isNaN(value)) {
    return Promise.reject("유효한 숫자를 입력해주세요");
  } else if (value <= 0) {
    return Promise.reject("0 이하는 입력이 불가능합니다");
  } else {
    return Promise.resolve();
  }
};

export const validatePresent = (rule, value) => {
  if (value.length === 0) {
    return Promise.reject("증정 상품을 입력해주세요");
  } else {
    return Promise.resolve();
  }
};

export const validatePeriod = (rule, value) => {
  if (!value) {
    return Promise.reject("숫자를 입력해주세요");
  } else if (isNaN(value)) {
    return Promise.reject("유효한 숫자를 입력해주세요");
  } else if (value <= 0) {
    return Promise.reject("0 이하는 입력이 불가능합니다");
  } else {
    return Promise.resolve();
  }
};

export const validateDate = (rule, value) => {
  if (!value) {
    return Promise.reject("날짜를 입력해주세요");
  } else {
    return Promise.resolve();
  }
};

export const validateCount = (rule, value) => {
  if (!value) {
    return Promise.reject("쿠폰 수량을 입력해주세요");
  } else if (isNaN(value)) {
    return Promise.reject("유효한 숫자를 입력해주세요");
  } else if (value <= 0) {
    return Promise.reject("최소 1장(매)이상 입력 가능합니다");
  } else {
    return Promise.resolve();
  }
};

export const validateDateRange = (rule, value, callback) => {
  if (value && value.length === 2) {
    return Promise.resolve();
  } else {
    return Promise.reject("기간을 입력해주세요");
  }
};

export const validateSendHour = (value) => {
  if (!value) {
    return Promise.reject("시간을 입력해주세요.");
  }

  // if(value)
};

export const validateAutoMessageName = (rule, value) => {
  if (!value) {
    return Promise.reject(
      "자동알림 템플릿 명을 입력하지 않으면 생성할 수 없습니다."
    );
  }
};

export const validateTitle = (rule, value) => {
  if (!value) {
    return Promise.reject("제목을 입력하지 않으면 생성할 수 없습니다.");
  }
};

export const validateBody = (rule, value) => {
  if (!value) {
    return Promise.reject("내용을 입력하지 않으면 생성할 수 없습니다.");
  }
};
