import {
  all,
  fork,
  take,
  call,
  put,
  takeEvery,
  takeLatest,
  throttle,
  delay,
} from "redux-saga/effects";

import * as oauthAPI from "../api/oautAPI";

import {
  LOAD_SERVER_JSON_REQUEST,
  LOAD_SERVER_JSON_SUCCESS,
  LOAD_SERVER_JSON_FAILURE,
  CHECK_TOKEN_REQUEST,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_FAILURE,
} from "../reducers/oauth";

//check Refresh Token
function* CheckRefreshToken(action) {
  try {
    const accessToken = yield call(oauthAPI.checkRefreshTokenAPI, action.data);

    if (accessToken.token) {
      yield put({
        type: CHECK_TOKEN_SUCCESS,
        data: {
          accessToken,
        },
      });
    } else {
      throw new Error();
    }
  } catch (err) {
    yield put({
      type: CHECK_TOKEN_FAILURE,
      data: err.response,
    });
  }
}

function* watchCheckRefreshToken() {
  yield takeLatest(CHECK_TOKEN_REQUEST, CheckRefreshToken);
}

function* loadServerJSONInfo() {
  try {
    const result = yield call(oauthAPI.getServerJSON);

    yield put({
      type: LOAD_SERVER_JSON_SUCCESS,
      data: result,
    });
  } catch (err) {
    yield put({
      type: LOAD_SERVER_JSON_FAILURE,
      data: err.response.data,
    });
  }
}

function* watchLoadServerJSONInfo() {
  yield takeLatest(LOAD_SERVER_JSON_REQUEST, loadServerJSONInfo);
}

//results
export default function* oauthSaga() {
  yield all([fork(watchLoadServerJSONInfo), fork(watchCheckRefreshToken)]);
}
