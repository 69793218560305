export const commonTheme = {
  background: {
    black1: "#000000",
    black2: "#121212",
    black3: "#222222",
    black4: "#2c2c2c",
    black5: "#383838",
    black6: "#414141",
    black7: "#4a4a4a",
    black8: "#7c7c7c",
    black9: "#cccccc",
    black10: "#ffffff",
  },
  font: {
    black1: "#000000",
    black2: "#7c7c7c",
    black3: "#aeaeae", // 준에게 컨펌
    black4: "#cccccc",
    black5: "#ffffff",
  },
  crm: {
    brown1: "#992800",
    brown2: "#BF3200",
    brown3: "#D95700",
    brown4: "#fa6400",
    brown5: "#FA943D",
    brown6: "#F5B98A",
    brown7: "#FFD9C0",
    brown8: "#FFEAD0",
    brown9: "#FDF3EC",
  },
  calender: {
    blue1: "#001799",
    blue2: "#003DCB",
    blue3: "#0066FF",
    blue4: "#3B99F5",
    blue5: "#8CBCFA",
    blue6: "#B2D2FC",
    blue7: "#D9E9FD",
    blue8: "#EBF3FE",
  },
  brand: {
    pink1: "#780034",
    pink2: "#9E0542",
    pink3: "#C40F53",
    pink4: "#EA1F63",
    pink5: "#FF7398",
    pink6: "#FF99B3",
    pink7: "#FFADC2",
    pink8: "#FFBDCE",
  },
  sign: {
    yellow1: "#A57801",
    yellow2: "#CE9601",
    yellow3: "#E5A701",
    yellow4: "#FFBA01",
    yellow5: "#FFC834",
    yellow6: "#FFD35D",
    yellow7: "#FFDC7D",
    yellow8: "#FFE397",
  },
  alert: {
    reject: "#EB5252",
    approve: "#65B49F",
    warning: "#F6CA4F",
    wait: "#FFBA01",
  },
};
