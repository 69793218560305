import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ActiveColumn = ({ record }) => {
  const [text, setText] = useState(() => {
    if (record.active) {
      if (record.period_type === "DAYS") {
        return "발행가능";
      } else if (record.period_type === "SPECIFIC") {
        const now = new Date();
        // 시간을 0시 0분 0초로 설정합니다.
        const jgcpPeriodEndDate = new Date(record.jgcp_period_end);
        jgcpPeriodEndDate.setHours(23, 59, 59, 59);
        if (jgcpPeriodEndDate < now) {
          return "발행불가";
        }
        return "발행가능";
      }
    } else {
      return "발행불가";
    }
  });

  useEffect(() => {
    setText(() => {
      if (record.active) {
        if (record.period_type === "DAYS") {
          return "발행가능";
        } else if (record.period_type === "SPECIFIC") {
          const now = new Date();
          // 시간을 0시 0분 0초로 설정합니다.
          const jgcpPeriodEndDate = new Date(record.jgcp_period_end);
          jgcpPeriodEndDate.setHours(23, 59, 59, 59);
          if (jgcpPeriodEndDate < now) {
            return "발행불가";
          }
          return "발행가능";
        }
      } else {
        return "발행불가";
      }
    });
  }, [record]);

  return <ActiveColumns active={text}>{text}</ActiveColumns>;
};

const commonColumnStyle = `
font-family: "Noto Sans KR-Regular";
font-style: normal;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
justify-content: center;
text-align: center `;

const ActiveColumns = styled.div`
  color: ${(props) => (props.active === "발행가능" ? "#65B49F" : "#EB5252")};
  ${commonColumnStyle}
  font-family: "Noto Sans KR-Bold";
  font-style: normal;
  font-weight: 700;
`;

export default ActiveColumn;
