import produce from "immer";
import moment from "moment";

// API와 상호작용하는 ACTION 정의
export const GET_COUPON = "GET_COUPON";
export const GET_COUPON_SUCCESS = "GET_COUPON_SUCCESS";
export const GET_COUPON_FAILURE = "GET_COUPON_FAILURE";

export const CREATE_COUPON = "CREATE_COUPON";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAILURE = "CREATE_COUPON_FAILURE";

export const MODIFY_COUPON = "MODIFY_COUPON";
export const MODIFY_COUPON_SUCCESS = "MODIFY_COUPON_SUCCESS";
export const MODIFY_COUPON_FAILURE = "MODIFY_COUPON_FAILURE";

export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAILURE = "DELETE_COUPON_FAILURE";

export const GET_MEMBER_COUPON = "GET_MEMBER_COUPON";
export const GET_MEMBER_COUPON_SUCCESS = "GET_MEMBER_COUPON_SUCCESS";
export const GET_MEMBER_COUPON_FAILURE = "GET_MEMBER_COUPON_FAILURE";

export const RETRIEVE_MEMBER_COUPON = "RETRIEVE_MEMBER_COUPON";
export const RETRIEVE_MEMBER_COUPON_SUCCESS = "RETRIEVE_MEMBER_COUPON_SUCCESS";
export const RETRIEVE_MEMBER_COUPON_FAILURE = "RETRIEVE_MEMBER_COUPON_FAILURE";

export const GET_ISSUED_COUPON = "GET_ISSUED_COUPON";
export const GET_ISSUED_COUPON_SUCCESS = "GET_ISSUED_COUPON_SUCCESS";
export const GET_ISSUED_COUPON_FAILURE = "GET_ISSUED_COUPON_FAILURE";

export const RETRIEVE_ISSUED_COUPON = "RETRIEVE_ISSUED_COUPON";
export const RETRIEVE_ISSUED_COUPON_SUCCESS = "RETRIEVE_ISSUED_COUPON_SUCCESS";
export const RETRIEVE_ISSUED_COUPON_FAILURE = "RETRIEVE_ISSUED_COUPON_FAILURE";

export const GET_CRM_PRODUCT = "GET_CRM_PRODUCT";
export const GET_CRM_PRODUCT_SUCCESS = "GET_CRM_PRODUCT_SUCCESS";
export const GET_CRM_PRODUCT_FAILURE = "GET_CRM_PRODUCT_FAILURE";

export const GET_MARKET_PRODUCT = "GET_MARKET_PRODUCT";
export const GET_MARKET_PRODUCT_SUCCESS = "GET_MARKET_PRODUCT_SUCCESS";
export const GET_MARKET_PRODUCT_FAILURE = "GET_MARKET_PRODUCT_FAILURE";

export const GET_GOODS_BY_KEYS = "GET_GOODS_BY_KEY";
export const GET_GOODS_BY_KEYS_SUCCESS = "GET_GOODS_BY_KEY_SUCCESS";
export const GET_GOODS_BY_KEYS_FAILURE = "GET_GOODS_BY_KEY_FAILURE";

// API와 상호작용하는 ACTION 등록
/** 쿠폰을 가져오는 ACTION */
export const getCouponAction = (data) => ({
  type: GET_COUPON,
  data,
});
/** 쿠폰을 가져오는 ACTION */
export const createCouponAction = (data) => ({
  type: CREATE_COUPON,
  data,
});
/** 쿠폰을 수정하는 ACTION */
export const modifyCouponAction = (data) => ({
  type: MODIFY_COUPON,
  data,
});
/** 쿠폰을 삭제하는 ACTION */
export const deleteCouponAction = (data) => ({
  type: DELETE_COUPON,
  data,
});
/** 회원의 쿠폰 목록을 가져오는 ACTION */
export const getMemberCouponAction = (data) => ({
  type: GET_MEMBER_COUPON,
  data,
});
/** 회원의 쿠폰들을 회수하는 ACTION */
export const retrieveMemberCouponAction = (data) => ({
  type: RETRIEVE_MEMBER_COUPON,
  data,
});

/** 발행된 쿠폰들을 가져오는 ACTION */
export const getIssuedCouponAction = (data) => ({
  type: GET_ISSUED_COUPON,
  data,
});

/** 발행된 쿠폰을 회수하는 ACTION */
export const retrieveIssuedCouponAction = (data) => ({
  type: RETRIEVE_ISSUED_COUPON,
  data,
});

/** CRM의 상품을 가져오는 ACTION */
export const getCrmProductAction = (data) => ({
  type: GET_CRM_PRODUCT,
  data,
});
/** 마켓의 상품을 가져오는 ACTION */
export const getMarketProductAction = (data) => ({
  type: GET_MARKET_PRODUCT,
  data,
});
/** 굿즈 키로 상품을 가져오는 ACTION */
export const getGoodsByKeysAction = (data) => ({
  type: GET_GOODS_BY_KEYS,
  data,
});

export const initialState = {
  getCouponLoading: false,
  getCouponDone: false,
  getCouponError: false,

  createCouponLoading: false,
  createCouponDone: false,
  createCouponError: false,

  modifyCouponLoading: false,
  modifyCouponDone: false,
  modifyCouponError: false,

  deleteCouponLoading: false,
  deleteCouponDone: false,
  deleteCouponError: false,

  getMemberCouponLoading: false,
  getMemberCouponDone: false,
  getMemberCouponError: false,

  retrieveMemberCouponLoading: false,
  retrieveMemberCouponDone: false,
  retrieveMemberCouponError: false,

  getIssuedCouponLoading: false,
  getIssuedCouponDone: false,
  getIssuedCouponError: false,

  retrieveIssuedCouponLoading: false,
  retrieveIssuedCouponDone: false,
  retrieveIssuedCouponError: false,

  getCrmProductLoading: false,
  getCrmProductDone: false,
  getCrmProductError: false,

  getMarketProductLoading: false,
  getMarketProductDone: false,
  getMarketProductError: false,

  getGoodsByKeysLoading: false,
  getGoodsByKeysDone: false,
  getGoodsByKeysError: false,

  couponList: null,

  issuedCouponList: null,

  crmProductList: null,

  marketProductList: null,

  goodsList: null,
};

/** 쿠폰과 관련된 ACTION에 대한 REDUCER */
const couponReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_COUPON:
        draft.getCouponDone = false;
        draft.getCouponError = null;
        draft.getCouponLoading = true;
        break;
      case GET_COUPON_SUCCESS:
        draft.couponList = action.data;
        draft.getCouponDone = true;
        draft.getCouponError = false;
        draft.getCouponLoading = false;
        break;
      case GET_COUPON_FAILURE:
        draft.getCouponError = action.error;
        draft.getCouponLoading = false;
        break;
      case CREATE_COUPON:
        draft.modifyCouponDone = false;
        draft.createCouponDone = false;
        draft.createCouponError = null;
        draft.createCouponLoading = true;
        break;
      case CREATE_COUPON_SUCCESS:
        draft.couponList.unshift(action.data);
        draft.createCouponDone = true;
        draft.createCouponError = false;
        draft.createCouponLoading = false;
        break;
      case CREATE_COUPON_FAILURE:
        draft.createCouponError = action.error;
        draft.createCouponLoading = false;
        break;
      case MODIFY_COUPON:
        draft.createCouponDone = false;
        draft.modifyCouponDone = false;
        draft.modifyCouponError = null;
        draft.modifyCouponLoading = true;
        break;
      case MODIFY_COUPON_SUCCESS:
        draft.couponList = draft.couponList.map((coupon) =>
          coupon.jgcp_key === action.data.jgcp_key ? action.data : coupon
        );
        draft.modifyCouponDone = true;
        draft.modifyCouponError = false;
        draft.modifyCouponLoading = false;
        break;
      case MODIFY_COUPON_FAILURE:
        draft.modifyCouponError = action.error;
        draft.modifyCouponLoading = false;
        break;
      case DELETE_COUPON:
        draft.deleteCouponDone = false;
        draft.deleteCouponError = null;
        draft.deleteCouponLoading = true;
        break;
      case DELETE_COUPON_SUCCESS:
        let deletedCouponList = action.data.map((item) => item.jgcp_key);
        draft.couponList = draft.couponList.filter(
          (coupon) => !deletedCouponList.includes(coupon.jgcp_key)
        );
        draft.deleteCouponDone = true;
        draft.deleteCouponError = false;
        draft.deleteCouponLoading = false;
        draft.selectedRow = null;
        break;
      case DELETE_COUPON_FAILURE:
        draft.deleteCouponError = action.error;
        draft.deleteCouponLoading = false;
        break;
      case GET_MEMBER_COUPON:
        draft.getMemberCouponDone = false;
        draft.getMemberCouponError = null;
        draft.getMemberCouponLoading = true;
        break;
      case GET_MEMBER_COUPON_SUCCESS:
        draft.getMemberCouponDone = true;
        draft.getMemberCouponError = false;
        draft.couponList = action.data;
        break;
      case GET_MEMBER_COUPON_FAILURE:
        draft.getMemberCouponError = action.error;
        draft.getMemberCouponLoading = false;
        break;
      case GET_ISSUED_COUPON:
        draft.getIssuedCouponDone = false;
        draft.getIssuedCouponError = null;
        draft.getIssuedCouponLoading = true;
        break;
      case GET_ISSUED_COUPON_SUCCESS:
        draft.issuedCouponList = action.data;
        draft.getIssuedCouponDone = true;
        draft.getIssuedCouponError = false;
        draft.getIssuedCouponLoading = false;

        break;
      case GET_ISSUED_COUPON_FAILURE:
        draft.getIssuedCouponError = action.error;
        draft.getIssuedCouponLoading = false;
        break;
      case RETRIEVE_ISSUED_COUPON:
        draft.retrieveIssuedCouponLoading = true;
        draft.retrieveIssuedCouponDone = null;
        draft.retrieveIssuedCouponError = true;
        break;
      case RETRIEVE_ISSUED_COUPON_SUCCESS:
        for (let start = 0; start < action.data.length; start++) {
          draft.issuedCouponList = draft.issuedCouponList.map((coupon) =>
            coupon.jgc_key === action.data[start].jgc_key
              ? action.data[start]
              : coupon
          );
        }
        draft.retrieveIssuedCouponLoading = false;
        draft.retrieveIssuedCouponDone = false;
        draft.selectedIssuedCoupon = null;
        break;
      case RETRIEVE_ISSUED_COUPON_FAILURE:
        draft.retrieveIssuedCouponError = action.error;
        draft.retrieveIssuedCouponLoading = false;
        break;
      case GET_CRM_PRODUCT:
        draft.getCrmProductLoading = true;
        draft.getCrmProductDone = false;
        draft.getCrmProductError = false;
        break;
      case GET_CRM_PRODUCT_SUCCESS:
        draft.crmProductList = action.data;
        draft.getCrmProductLoading = false;
        draft.getCrmProductDone = true;
        break;
      case GET_CRM_PRODUCT_FAILURE:
        draft.getCrmProductLoading = false;
        draft.getCrmProductError = true;
        break;
      case GET_MARKET_PRODUCT:
        draft.getMarketProductLoading = true;
        draft.getMarketProductDone = false;
        draft.getMarketProductError = false;
        break;
      case GET_MARKET_PRODUCT_SUCCESS:
        draft.marketProductList = action.data;
        draft.getMarketProductLoading = false;
        draft.getMarketProductDone = true;
        break;
      case GET_MARKET_PRODUCT_FAILURE:
        draft.getMarketProductLoading = false;
        draft.getMarketProductError = true;
        break;
      case GET_GOODS_BY_KEYS:
        draft.getGoodsByKeysLoading = true;
        draft.getGoodsByKeysDone = false;
        draft.getGoodsByKeysError = false;
        break;
      case GET_GOODS_BY_KEYS_SUCCESS:
        draft.goodsList = action.data;
        draft.getGoodsByKeysLoading = false;
        draft.getGoodsByKeysDone = true;
        break;
      case GET_GOODS_BY_KEYS_FAILURE:
        draft.getGoodsByKeysLoading = false;
        draft.getGoodsByKeysError = true;
        break;

      default:
        return state;
    }
  });
};

export default couponReducer;
