import {
  all,
  fork,
  take,
  call,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import couponSaga from "./coupon";
import messageSaga from "./message";
import groupSaga from "./group";
import oauthSaga from "./oauth";
// axios.defaults.baseURL = 'https://server.broj.shop/';

/** Project내 모든 Saga를 통합적으로 관리하는 RootSaga  */
export default function* rootSaga() {
  yield all([
    fork(couponSaga), 
    fork(messageSaga), 
    fork(groupSaga),
    fork(oauthSaga)
  ]);
}
