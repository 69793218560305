import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  ActionButtonGroup,
  ModalScreen,
  PositionController,
  Spacer,
  Text,
  Title,
} from "../styles/commonStyle";
import { commonTheme } from "../styles/theme";

/** 생성, 수정, 삭제 등의 확인이 필요할 때 사용하는 모달창
 * @param multipleScreen boolean
 * @param multipleButton boolean
 * @param primaryColor String
 * @param body Component
 * @param secondBody Component
 * @param headerIcon String url
 * @param secondHeaderIcon String url
 * @param warningText String
 * @param secondWarningText String
 * @param firstButtonText String
 * @param secondButtonText String
 * @param title String
 * @param secondTitle String
 * @param handleFirstButton function
 * @param handleSecondButton function
 */
const NoticeModal = ({
  multipleScreen, // 창 개수
  multipleButton, // 첫번째 스크린에서 버튼의 개수
  primaryColor, // 첫번째 스크린의 주 색상
  body, // 첫번째 스크린에서 body에 들어갈 컴포넌트
  headerIcon, // 첫번째 스크린에 들어갈 헤더 Icon
  secondHeaderIcon, // 두번째 스크린에 들어갈 헤더 Icon
  secondBody, // multipleScreen이 true 일 때, 두번째 스크린에서 body에 들어갈 컴포넌트
  warningText, // 첫번째 스크린에서 body 하단에 들어갈 text
  secondWarningText, // multipleScreen이 true 일 때, 두번째 스크린에서 body 하단에 들어갈 text
  handleFirstButton, // 확인 또는 취소를 눌렀을 때 작동해야할 함수
  handleSecondButton,
  firstButtonText, // multipleButton이 활성화 되어있을 때, 왼쪽 버튼의 문자
  secondButtonText, // multipleButton이 활성화 되어있을 때, 오른쪽 버튼의 문자
  title, // 첫번째 스크린에 들어갈 Title
  secondTitle, // 두번째 스크린에 들어갈 Title
}) => {
  const [nextScreen, setNextScreen] = useState(false);
  const nextStep = () => {
    if (handleSecondButton) {
      handleSecondButton();
    }
    setNextScreen(true);
  };

  return (
    <ModalScreen zIndex={100600}>
      <PositionController>
        {multipleScreen ? (
          <PopUpContainer>
            <PopUpHeader primaryColor={nextScreen ? "#65B49F" : primaryColor}>
              <Icon
                src={
                  process.env.PUBLIC_URL +
                  (nextScreen ? secondHeaderIcon : headerIcon)
                }
              />
              <CloseButton type="button" onClick={handleFirstButton}>
                <CloseIcon
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icon/white/x.png"
                  }
                />
              </CloseButton>
            </PopUpHeader>
            <PopUpContent>
              <Title color={commonTheme.font.black1}>
                {nextScreen ? secondTitle : title}
              </Title>
              <Spacer />
              <NoticeContainer>
                {nextScreen ? secondBody : body}
              </NoticeContainer>
              <Spacer size={3} />
              <div
                style={{ width: "100%", height: "18px", position: "relative" }}
              >
                <Text
                  color={primaryColor && !nextScreen ? primaryColor : "#65B49F"}
                  size={12}
                  lineHeight={17}
                  fontFamily="Noto Sans KR-Bold"
                  fontWeight={700}
                >
                  {nextScreen ? secondWarningText : warningText}
                </Text>
              </div>
              <Spacer size={7} />
              <ActionButtonGroup style={{ height: "auto" }}>
                {nextScreen ? (
                  <CustomBtn primaryColor="#65B49F" onClick={handleFirstButton}>
                    확인
                  </CustomBtn>
                ) : multipleButton ? (
                  <React.Fragment>
                    {" "}
                    <CustomBtn
                      fontColor="#000"
                      border
                      onClick={handleFirstButton}
                    >
                      {firstButtonText}
                    </CustomBtn>
                    <Spacer size={12} />
                    <CustomBtn primaryColor={primaryColor} onClick={nextStep}>
                      {secondButtonText}
                    </CustomBtn>
                  </React.Fragment>
                ) : (
                  <CustomBtn
                    primaryColor={primaryColor}
                    onClick={handleFirstButton}
                  >
                    {firstButtonText}
                  </CustomBtn>
                )}
              </ActionButtonGroup>
            </PopUpContent>
          </PopUpContainer>
        ) : (
          <PopUpContainer>
            <PopUpHeader primaryColor={primaryColor}>
              <Icon src={process.env.PUBLIC_URL + headerIcon} />
              <CloseButton type="button" onClick={handleFirstButton}>
                <CloseIcon
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icon/white/x.png"
                  }
                />
              </CloseButton>
            </PopUpHeader>
            <PopUpContent>
              <Title color={commonTheme.font.black1}>{title ?? ""}</Title>
              <Spacer />
              <NoticeContainer>{body}</NoticeContainer>
              <Spacer />
              <div
                style={{
                  width: "100%",
                  height: "18px",
                  position: "relative",
                  textAlign: "start",
                }}
              >
                <Text
                  color={primaryColor ? primaryColor : "#65B49F"}
                  size={12}
                  lineHeight={17}
                  fontFamily="Noto Sans KR-Bold"
                  fontWeight={700}
                >
                  {warningText}
                </Text>
              </div>
              <Spacer />
              <ActionButtonGroup style={{ height: "auto" }}>
                {multipleButton ? (
                  <React.Fragment>
                    {" "}
                    <CustomBtn
                      fontColor="#000"
                      border
                      onClick={handleFirstButton}
                    >
                      {firstButtonText}
                    </CustomBtn>
                    <Spacer size={12} />
                    <CustomBtn
                      primaryColor={primaryColor}
                      onClick={handleSecondButton}
                    >
                      {secondButtonText}
                    </CustomBtn>
                  </React.Fragment>
                ) : (
                  <CustomBtn
                    primaryColor={primaryColor}
                    onClick={handleFirstButton}
                  >
                    {firstButtonText}
                  </CustomBtn>
                )}
              </ActionButtonGroup>
            </PopUpContent>
          </PopUpContainer>
        )}
      </PositionController>
    </ModalScreen>
  );
};

const ButtonStyle = `
border: none;
font-family: "Noto Sans KR";
font-style: normal;
font-weight: 500;
font-size:14px;
line-height: 20px;
text-align: center;
letter-spacing: -0.670707px;
border-radius: 28px;
width:120px;
height:36px;
:hover {
  cursor: pointer;
}
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  :hover {
    cursor: pointer;
  }
`;

const CustomBtn = styled.button`
  ${ButtonStyle}
  background:  ${(props) => (props.primaryColor ? props.primaryColor : "#fff")};
  border: ${(props) => (props.border ? "1px solid #979797" : "none")};
  /* #65B49F */
  color: ${(props) => (props.fontColor ? props.fontColor : "#fff")};
`;

const NoticeContainer = styled.div`
  width: 426px;
  height: 348px;
  border: 1px solid #979797;
  border-radius: 8px;
  padding: 12px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  overflow: auto;
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
`;

const PopUpContainer = styled.div`
  position: relative;
  width: 450px;
  height: 573px;
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const PopUpHeader = styled.div`
  position: relative;
  border-radius: 15px 15px 0px 0px;
  width: 450px;
  height: 84px;
  background: ${(props) =>
    props.primaryColor ? props.primaryColor : "#78B2A0"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopUpContent = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12px;
`;

export default NoticeModal;
