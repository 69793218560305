import { Button } from "antd";
import React from "react";
import styled from "styled-components";

export const CustomButton = ({
  background,
  color,
  width,
  height,
  borderRadius,
  fontWeight,
  fontSize,
  lineHeight,
  text,
  onClick,
  position,
  left,
  right,
  top,
  bottom,
  htmlType,
  zIndex,
  disabled,
}) => {
  return (
    <CustomBtn
      disabled={disabled}
      background={background}
      color={color}
      width={width}
      height={height}
      border_radius={borderRadius}
      font_weight={fontWeight}
      font_size={fontSize}
      line_height={lineHeight}
      onClick={onClick}
      position={position}
      left={left}
      right={right}
      top={top}
      bottom={bottom}
      htmlType={htmlType}
      z_index={zIndex}
    >
      {text ? `${text}` : "버튼 텍스트"}
    </CustomBtn>
  );
};

const CustomBtn = styled(Button)`
  border: none;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: ${(props) => (props.font_weight ? props.font_weight : 400)};
  font-size: ${(props) => (props.font_size ? `${props.font_size}` : `20px`)};
  line-height: ${(props) =>
    props.line_height ? `${props.line_height}` : `29px`};

  letter-spacing: -0.670707px;
  background: ${(props) =>
    props.background ? `${props.background}` : "#4A4A4A"};
  color: ${(props) => (props.color ? `${props.color}` : "#fff")};
  width: ${(props) => (props.width ? `${props.width}` : "450px")};
  height: ${(props) => (props.height ? `${props.height}` : "56px")};
  border-radius: ${(props) =>
    props.border_radius ? `${props.border_radius}` : `0`};
  position: ${(props) => (props.position ? `${props.position}` : "static")};
  top: ${(props) => (props.top ? `${props.top}` : null)};
  bottom: ${(props) => (props.bottom ? `${props.bottom}` : null)};
  left: ${(props) => (props.left ? `${props.left}` : null)};
  right: ${(props) => (props.right ? `${props.right}` : null)};
  z-index: ${(props) => (props.z_index ? props.z_index : null)};
  :hover,
  :focus {
    cursor: pointer;
    background: ${(props) => (props.background ? props.background : "#4A4A4A")};
    color: ${(props) => (props.color ? `${props.color}` : "#fff")};
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;
