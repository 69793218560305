import {
    Form,
    Input,
    Radio,
    Select,
    DatePicker,
    Switch,
    Checkbox,
    Divider,
    Popover,
    Layout,
    Tabs,
    Button,
    Space,
    InputNumber,
    Dropdown,
    Menu,
    Row,
    Col,
    message,
} from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
    ModalScreen,
    Spacer,
    Text,
    PositionController,
    Title,
    ActionButtonGroup,
    CloseButton,
    Icon,
} from '../styles/commonStyle';
import { commonTheme } from '../styles/theme';
import { CustomButton } from './CustomButton';
import shortId from 'shortid';
import { getMessagePresignedUrl } from '../api/s3';
import { putSenderNumberAdd } from '../api/messageAPI';
import { useDispatch, useSelector } from 'react-redux';
import NoticeModal from './NoticeModal';
import { getCertificateDataAction } from '../reducers/message';

export default function AddSenderNumberModal({
    closeAddSenderNumberModal,
    jgroupData,
}) {
    const jgroupKey = useSelector(
        (state) => state.groupReducer.jgroup_key_inCookie,
    );
    const [selectedFile, setSelectedFile] = useState(null);
    const [senderNumber, setSenderNumber] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);

    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const onSenderNumberChange = (e) => {
        let check = /^[0-9]+$/;
        if (e.target.value && !check.test(e.target.value)) {
            return;
        }
        setSenderNumber(e.target.value);
    };

    const fileSettng = async (imageName) => {
        //url 생성
        const uploadUrl = `jgroup/${jgroupKey}/files/${imageName}`;

        //presigned url 생성
        const messagePresignedURL = await getMessagePresignedUrl(uploadUrl);

        // const fileObj = await fetch(selectedFile);
        // const imageBlob = await fileObj.blob();

        const putImageReult = await fetch(messagePresignedURL, {
            method: 'PUT',
            body: selectedFile,
            headers: {
                'Content-Type': 'image/jpeg',
            },
        });
        return putImageReult;
    };

    const onFinish = (values) => {
        if (!selectedFile) {
            message.warning({
                content: '파일을 선택 해주세요.',
                style: {
                    marginTop: '90vh',
                },
                duration: 1,
            });
            return;
        }
        if (!senderNumber) {
            message.warning({
                content: '번호를 입력해 주세요.',
                style: {
                    marginTop: '90vh',
                },
                duration: 1,
            });
            return;
        }

        const imageName = `${shortId.generate()}.png`;
        const data = {
            jgroup_key: jgroupKey,
            file_name: imageName,
            jgroup_name: jgroupData.jgroup_name,
            phone_number: senderNumber,
        };
        fileSettng(imageName);
        putSenderNumberAdd(data);
        setIsSubmit(true);
    };

    return (
        <ModalScreen>
            <PositionController>
                <Form onFinish={onFinish}>
                    <ModalContainer>
                        <ModalHeader>
                            <LeftHeader>
                                <Title>문자서비스 신청</Title>
                            </LeftHeader>
                            <RightHeader>
                                <CloseButton
                                    onClick={closeAddSenderNumberModal}
                                >
                                    <CloseIcon
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/assets/images/icon/white/x.png'
                                        }
                                    ></CloseIcon>
                                </CloseButton>
                            </RightHeader>
                        </ModalHeader>
                        <FormWrapper>
                            <div
                                style={{
                                    marginTop: '12px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{ marginBottom: '20px' }}
                                    size={16}
                                >
                                    문자서비스 이용을 위해서는 아래 절차를 통해
                                    발신번호 가입증명원 제출이 필요합니다.
                                </Text>
                                <Text size={16}>
                                    [KT 가입증명원]
                                    <br />
                                    기업고객은 1544-0114 KT기업고객센터로
                                    전화하여 "통신가입증명원" 발급 요청 팩스로
                                    사업자등록증 및 대표자 신분증 접수 후 팩스로
                                    가입증명원 수신 가능 (팩스로만 수신 받을 수
                                    있는 것에 유의) 개인은 KT 사이트(
                                    <a
                                        style={{ textDecoration: 'underline' }}
                                        href="https://www.kt.com"
                                        target="_blank"
                                    >
                                        https://www.kt.com
                                    </a>
                                    ) 로그인 > 마이 > 가입정보 > 하단 가입증명원
                                    인쇄 (개인) / 마이비즈 바로가기(법인) > 하단
                                    가입증명원 인쇄
                                </Text>
                                <Text size={16} style={{ marginTop: '20px' }}>
                                    [LG U+ 가입사실 확인서] <br />
                                    모바일의 경우 고객센터(LG U+망 전화번호 114)
                                    문의 후 팩스로 받기 또는 LG U+ 사이트({' '}
                                    <a
                                        style={{ textDecoration: 'underline' }}
                                        href="http://www.uplus.co.kr"
                                        target="_blank"
                                    >
                                        http://www.uplus.co.kr
                                    </a>
                                    ) 접속 후 로그인 고객지원 > 상품가입안내 >
                                    가입조회 클릭 후 실명인증 및 가입조회 인터넷
                                    전화의 경우 고객센터(전화번호 101) 문의 후
                                    팩스로 받기, 대표번호(법인) 가입확인서
                                    발급시 고객센터 문의(1544-0001)
                                </Text>
                                <Text size={16} style={{ marginTop: '20px' }}>
                                    [SKT 이용계약증명서]
                                    <br />
                                    tworld 사이트({' '}
                                    <a
                                        style={{ textDecoration: 'underline' }}
                                        href="http://www.tworld.co.kr"
                                        target="_blank"
                                    >
                                        http://www.tworld.co.kr
                                    </a>
                                    ) 접속 후 로그인 my T > 조회 > 나의 가입정보
                                    > 이용계약증명서 조회
                                </Text>
                                <Text
                                    style={{ marginTop: '20px' }}
                                    size="18"
                                    fontWeight="700"
                                >
                                    서류제출시 주의사항
                                </Text>
                                <Text size={16}>
                                    ☛ 인쇄가 불가한 경우 모니터 화면을
                                    휴대폰으로 촬영한 이미지로 대체 가능하나,
                                    사진으로 서류 내용 확인이 어려운 경우
                                    접수불가 합니다. <br />☛ 제출된 서류에서
                                    가입자명과 핸드폰번호가 모두 확인 할 수
                                    있어야 합니다.
                                </Text>
                                <Text size={16}>
                                    jpg와 png 파일(캡처사진)만 업로드
                                    가능합니다.
                                </Text>
                            </div>
                            <Form.Item name="file_name">
                                <Input
                                    onChange={onFileChange}
                                    accept="image/*"
                                    style={{
                                        background: 'none',
                                        marginTop: '30px',
                                    }}
                                    type="file"
                                />
                            </Form.Item>
                            <div
                                style={{
                                    marginTop: '30px',
                                    //   width: "100px",
                                    display: 'flex',
                                    flexDirectio: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Form.Item>
                                    <Input
                                        // type="number"
                                        value={senderNumber}
                                        placeholder="서비스 등록할 발신번호 입력"
                                        onChange={onSenderNumberChange}
                                        autoComplete="off"
                                        style={{
                                            width: '200px',
                                            height: '35px',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <CustomButton
                                        htmlType="submit"
                                        background="#ff6200"
                                        width="80px"
                                        height="36px"
                                        text="제출"
                                    />
                                </Form.Item>
                            </div>
                        </FormWrapper>
                        {isSubmit && (
                            <NoticeModal
                                multipleScreen={false}
                                multipleButton={false}
                                title="발신번호 추가 요청 완료"
                                headerIcon="/assets/images/icon/white/v.png"
                                primaryColor="#78B2A0"
                                firstButtonText="확인"
                                body={
                                    <Text color="#000">
                                        발신번호 등록 신청이 완료되었습니다.{' '}
                                    </Text>
                                }
                                warningText="*  승인까지 영업일 기준 1~2일 소요됩니다. "
                                handleFirstButton={() => {
                                    document.body.style.overflow = 'unset';
                                    setIsSubmit(false);
                                    closeAddSenderNumberModal();
                                }}
                            />
                        )}
                    </ModalContainer>
                </Form>
            </PositionController>
        </ModalScreen>
    );
}

const ModalContainer = styled.div`
    position: relative;
    background: ${commonTheme.background.black4};
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    width: 900px;
    height: 671px;
    overflow: auto;
`;

const ModalHeader = styled.div`
    position: sticky;
    top: 0;
    background: ${commonTheme.background.black4};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px;
    padding-bottom: 0;
    z-index: 100;
`;

const LeftHeader = styled.div`
    display: flex;
    align-items: center;
`;

const RightHeader = styled.div`
    display: flex;
    align-items: center;
`;

const CloseIcon = styled.img`
    position: absolute;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
`;

const FormWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 30px;
    padding-top: 0;
`;
