import React, { useState } from "react";
import styled from "styled-components";
import { commonTheme } from "../styles/theme";
import { Icon, Text } from "../styles/commonStyle";
import CreateAndModifyCouponModal from "./CreateAndModifyCouponModal";
import { useSelector } from "react-redux";

/** 테이블의 쿠폰명 컬럼에 있는 데이터 위에 마우스를 올려두면 버튼이 생겨, 수정할 수 있는 모달창을 열 수 있는 컴포넌트입니다 */
export const NameColumn = ({ record, index }) => {
  const [showButton, setShowButton] = useState(false);
  const [mbClicked, setMbClicked] = useState(false);
  const dataSource = useSelector((state) => state.couponReducer.couponList);
  const handleMouseEnter = () => {
    setShowButton(true);
  };

  const handleMouseLeave = () => {
    setShowButton(false);
  };
  /** 쿠폰 수정 모달창을 여는 함수 */
  const openCreateAndModifyModal = () => {
    document.body.style.overflow = "hidden";

    setMbClicked((mbClicked) => !mbClicked);
  };
  /** 쿠폰 수정 모달창을 여는 함수 */
  const closeCreateAndModifyModal = () => {
    document.body.style.overflow = "unset";
    setShowButton(false);
    setMbClicked((mbClicked) => !mbClicked);
  };
  return (
    <CouponNameColumn
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {" "}
      {record.jgcp_name}
      {showButton && (
        <ModifyButton onClick={openCreateAndModifyModal}>
          <Icon
            src={process.env.PUBLIC_URL + "/assets/images/icon/white/edit.png"}
          />
          <Text color={commonTheme.font.black5}>수정</Text>
        </ModifyButton>
      )}
      {mbClicked && (
        <CreateAndModifyCouponModal
          isModify={mbClicked}
          handleChange={closeCreateAndModifyModal}
          record={record}
        />
      )}
    </CouponNameColumn>
  );
};

const CouponNameColumn = styled.div`
  font-family: "Noto Sans KR-Regular";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
`;

const ModifyButton = styled.button`
  position: absolute;
  width: 74px;
  height: 32px;
  background: ${commonTheme.background.black7};
  border: none;
  border-radius: 3px;
  padding: 7px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
    background: ${commonTheme.background.black6};
  }
`;
