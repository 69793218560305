import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Container, Icon, Spacer } from "../styles/commonStyle";
import { commonTheme } from "../styles/theme";
import CustomInput from "./CustomInput";

const MessageSearchBar = ({
  tab,
  onMessageKeywordChange,
  onPushNotificationKeywordChange,
  messageData,
  pushData,
}) => {
  return (
    <Container>
      {tab === "message" ? (
        <React.Fragment>
          {" "}
          <CustomInput
            suffix={
              <Icon
                style={{ width: "24px", height: "24px" }}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icon/button/search.png"
                }
              />
            }
            placeholder={"수신번호, 내용 검색"}
            width="232px"
            height="32px"
            border="none"
            borderRadius="3px"
            background="#4a4a4a"
            onChange={
              tab === "message"
                ? onMessageKeywordChange
                : onPushNotificationKeywordChange
            }
          />
          <Spacer />
        </React.Fragment>
      ) : null}

      <SearchBarLabel>조회된 목록</SearchBarLabel>
      <Spacer />
      {/* 필터링된 결과가 들어가야 하기 때문에 이후 filteredDatas라는 변수를 넣을
      예정 */}
      <SearchResult>
        {tab === "message"
          ? messageData
            ? messageData.length
            : null
          : pushData
          ? pushData.lamb_total_count
          : null}
        건
      </SearchResult>
    </Container>
  );
};

const SearchResult = styled.label`
  display: inline-block;
  color: ${commonTheme.font.black5};
`;

const SearchBarLabel = styled.label`
  display: inline-block;
  color: ${commonTheme.font.black3};
`;

export default MessageSearchBar;
