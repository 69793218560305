import axios from "axios";
import { Api } from "./Api";

export const getMessagePresignedUrl = async (url) => {
  try {
    const axiosBaseUrl = `${
      Api.ServerJSON.getInstance().BROJ_SERVER
    }japi/write/presignedurl`;

    const formData = new FormData();
    formData.append("object_key", url);
    formData.append("second", 5);

    const { data } = await axios.post(axiosBaseUrl, formData);

    if (data.result) {
      return data.result;
    }

    return null;
  } catch (error) {
    console.log(error);
  }
};
