import { Spin } from "antd";
import React from "react";
import { ModalScreen, PositionController } from "../styles/commonStyle";

const CustomSpinner = ({ loading }) => {
  return (
    <ModalScreen style={{ zIndex: "100000" }}>
      <PositionController>
        {" "}
        <Spin size="large" />{" "}
      </PositionController>
    </ModalScreen>
  );
};

export default CustomSpinner;
