import { Image } from "antd";
import React from "react";
import { Icon } from "../styles/commonStyle";

export default function ImageBox(props) {
  const { src, id, deleteImage } = props;
  return (
    // <div style={{ position: "relative" }}>
    <div
      style={{
        marginRight: 10,
        width: 90,
        height: 90,
        border: "1px solid #fff",
        borderRadius: 5,
        position: "relative",
        // display: "flex",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Icon
        style={{ width: "100%", height: "100%" }}
        src={src}
        alt={`${src}-${id}`}
      />
      <Icon
        onClick={() => deleteImage(id)}
        style={{
          position: "absolute",
          width: 20,
          height: 20,
          right: -9,
          top: -9,
          cursor: "pointer",
        }}
        src={process.env.PUBLIC_URL + "assets/images/icon/cancel_icon.png"}
      />
    </div>

    // </div>
  );
}
