import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import AutomaticMessage from "../components/AutomaticMessage";
import MessageWrite from "../components/MesasgeWrite";
import { Content } from "antd/lib/layout/layout";
import "../styles/Message.css";
import { getAutoMessageSetting } from "../api/messageAPI";
import { useDispatch, useSelector } from "react-redux";
import { getJgroupAction } from "../reducers/group";
import {
  getCertificateDataAction,
  getAutoMessageSettingAction,
} from "../reducers/message";

const { TabPane } = Tabs;

const tabs = {
  url: ["/", "/messageHistory", "/couponManage"],
  title: ["메세지 전송", "메세지 내역", "쿠폰 관리"],
};
const MessageTransfer = (props) => {
  const { jgroup_key_cookie } = props;
  const jgroupKey = useSelector(
    (state) => state.groupReducer.jgroup_key_inCookie
  );
  const checkTokenDone = useSelector(
    (state) => state.oauthReducer.checkTokenDone
  );
  const dispatch = useDispatch();
  const jgroupData = useSelector((state) => state.groupReducer.jgroupData);
  const certificateDataList = useSelector(
    (state) => state.messageReducer.certificateData
  );
  const [selectedNumber, setSelectedNumber] = useState(null);
  useEffect(() => {
    // console.log("here!");
    // console.log("checlTokenDone", checkTokenDone);
    // console.log("jgroupKey", jgroupKey);
    if (jgroupKey && checkTokenDone) {
      dispatch(getJgroupAction({ jgroup_key: jgroupKey }));
      dispatch(getCertificateDataAction({ jgroup_key: jgroupKey }));
      dispatch(
        getAutoMessageSettingAction({
          jgroup_key: jgroupKey,
        })
      );
    }
  }, [jgroupKey, checkTokenDone]);

  useEffect(() => {
    if (Object.keys(jgroupData).length !== 0) {
      if (
        jgroupData.setting_value.default_automessage_calling_number.length > 0
      ) {
        setSelectedNumber(
          jgroupData.setting_value.default_automessage_calling_number
        );
      } else {
        return;
      }
    }
  }, [jgroupData]);

  return (
    <Layout className="message-send">
      {/* <Spacer /> */}
      <Content>
        <Tabs defaultActiveKey="2">
          {/* <TabPane style={{ margin: "0px" }} tab="메세지 작성" key="1">
            <MessageWrite />
          </TabPane> */}
          <TabPane style={{ margin: "0px" }} tab="자동알림" key="2">
            <AutomaticMessage
              jgroupData={jgroupData}
              certificateDataList={certificateDataList}
              selectedNumber={selectedNumber}
              setSelectedNumber={setSelectedNumber}
            />
          </TabPane>
        </Tabs>
      </Content>
    </Layout>
  );
};

const Spacer = styled.div`
  height: ${(props) => (props.size != null ? `${props.size}px` : `15px`)};
`;
export default MessageTransfer;
