import { Layout } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  CloseButton,
  ModalScreen,
  PositionController,
  Title,
} from "../styles/commonStyle";
import { commonTheme } from "../styles/theme";
import CouponProductTable from "./CouponProductTable";

/**
 * 적용 상품 버튼을 클릭했을 때, 보여지는 최상단 컴포넌트
 * @param tableData 적용 상품 테이블에 들어갈 데이터
 * @param handleChange 모달창을 닫는 함수
 */
const CouponProduct = ({ tableData, handleChange }) => {
  return (
    <ModalScreen>
      <PositionController>
        <ModalContainer>
          <div style={{ padding: "0" }}>
            <Layout
              style={{
                height: "671px",
                padding: "24px",
                background: "#2c2c2c",
              }}
            >
              <Header
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Title>적용 상품</Title>
                <CloseButton type="button" onClick={handleChange}>
                  <CloseIcon
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icon/white/x.png"
                    }
                  />
                </CloseButton>
              </Header>
              <Content>
                <CouponProductTable tableData={tableData} />
              </Content>
            </Layout>
          </div>
        </ModalContainer>
      </PositionController>
    </ModalScreen>
  );
};

const ModalContainer = styled.div`
  position: relative;
  background: ${commonTheme.background.black4};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  width: 900px;
  height: 671px;
  overflow: ${(props) => (props.stop ? "hidden" : "auto")};
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
`;

export default CouponProduct;
