import moment from "moment";
import styled from "styled-components";
import ActiveColumn from "./CouponTableActiveColumn";
import { HistoryColumn } from "./CouponTableHistoryColumn";
import { NameColumn } from "./CouponTableNameColumn";
import { ProductColumn } from "./CouponTableProductColumn";
import MessageContentColumn from "./MessageTableContentColumn";
import ShareLinkColumnData from "./ShareLinkColumnData";

const env = process.env.REACT_APP_ENVIRONMENT;
const config = require("../config/config.json")[env];

/**
@param title - 열 제목
@param dataIndex - 열에 표시되어야 하는 dataSource 배열의 데이터 키
@param key - 열의 고유 식별자입니다
@param width - 열의 길이
@param render - 열의 셀 내용 렌더링
*/
export const columns = [
  {
    title: "No",
    dataIndex: "key",
    key: "key",
    width: 56,
    render: (key, record, index) => <CustomColumn>{index + 1}</CustomColumn>,
  },
  {
    title: "상태",
    dataIndex: "active",
    key: "active",
    width: 75,
    render: (_, record) => <ActiveColumn record={record} />,
  },
  {
    title: "쿠폰명",
    dataIndex: "jgcp_name",
    key: "jgcp_name",
    width: 275,
    render: (_, record, index) => <NameColumn record={record} index={index} />,
  },
  {
    title: "설명",
    dataIndex: "reason",
    key: "reason",
    width: 196,
    render: (_, record) => <MessageContentColumn record={record} />,
  },
  {
    title: "적용 상품",
    dataIndex: "available_products",
    key: "available_products",
    width: 126,
    render: (_, record) => (
      <CustomColumn>
        <ProductColumn record={record} />
      </CustomColumn>
    ),
  },
  {
    title: "혜택",
    dataIndex: "benefit",
    key: "benefit",
    width: 196,
    render: (_, record) => (
      <CustomColumn>
        {record.gift_type === "DISCOUNT_PER"
          ? `할인율 ${record.gift_value}%`
          : record.gift_type === "DISCOUNT_PRICE"
          ? `할인액 ${record.gift_value}원`
          : record.present_name}
      </CustomColumn>
    ),
  },
  {
    title: "유효기간",
    dataIndex: "expiration",
    key: "expiration",
    width: 196,
    render: (_, record) => (
      <CustomColumn>
        {record.period_type === "DAYS"
          ? `발행일로부터 ${record.jgcp_preiod_day}일 이내`
          : `${moment(record.jgcp_period_start).format(
              "YYYY.MM.DD"
            )} ~ ${moment(record.jgcp_period_end).format("YYYY.MM.DD")}`}
      </CustomColumn>
    ),
  },
  {
    title: "발급 수량",
    dataIndex: "jgcp_limit_count",
    key: "jgcp_limit_count",
    width: 95,
    render: (_, record) => (
      <CustomColumn>
        {record.jgcp_limited
          ? `${record.jgcp_limit_count - record.issued_coupons_size}/${
              record.jgcp_limit_count
            }`
          : "무제한"}
      </CustomColumn>
    ),
  },
  // {
  //   title: "이미지",
  //   dataIndex: "image",
  //   key: "image",
  //   width: 95,
  // },
  {
    title: "발행내역",
    dataIndex: "history",
    key: "history",
    width: 126,
    render: (_, record, index) => <HistoryColumn record={record} />,
  },
  {
    title: "공유 링크",
    dataIndex: "encrypted_key",
    key: "encrypted_key",
    width: 200,
    render: (_, record) => {
      return <ShareLinkColumnData record={record} />;
    },
  },
  {
    title: "생성 일자",
    dataIndex: "create",
    key: "create",
    width: 126,
    render: (_, record) => (
      <CustomColumn>
        {moment(record.jgcp_created_dttm).format("YYYY.MM.DD HH:mm")}
      </CustomColumn>
    ),
  },
  {
    title: "최근 수정 일자",
    dataIndex: "modify",
    key: "modify",
    width: 126,
    render: (_, record) => (
      <CustomColumn>
        {moment(record.jgcp_updated_dttm).format("YYYY.MM.DD HH:mm")}
      </CustomColumn>
    ),
  },
];

export const historyColumns = [
  {
    title: "No",
    dataIndex: "key",
    key: "key",
    width: 56,
    render: (key, record, index) => <CustomColumn>{index + 1}</CustomColumn>,
  },
  {
    title: "발행 일자",
    dataIndex: "active",
    key: "active",
    width: 133,
    render: (_, record) => (
      <CustomColumn>
        {moment(record.jgc_created_dttm).format("YYYY.MM.DD HH:mm")}
      </CustomColumn>
    ),
  },
  {
    title: "고객명 (번호)",
    dataIndex: "jgcp_name",
    key: "jgcp_name",
    width: 275,
    render: (_, record, idx) => (
      <CustomColumn>
        {record.jgjm_member_phone_number
          ? `${
              record.jgjm_member_name
            } (${record.jgjm_member_phone_number.replace(
              /(\d{3})(\d{4})(\d{4})/,
              "$1-$2-$3"
            )} )`
          : null}
      </CustomColumn>
    ),
  },
  {
    title: "고객 사용 여부",
    dataIndex: "isUse",
    key: "isUse",
    width: 130,
    render: (_, record) => (
      <CustomColumn>{record.jgc_is_use ? "사용" : "미사용"}</CustomColumn>
    ),
  },
  {
    title: "전송 방법",
    dataIndex: "reason",
    key: "reason",
    width: 196,
    render: (_, record) => (
      <CustomColumn>
        {record.send_type === "NONE" ? "-" : record.send_type}
      </CustomColumn>
    ),
  },
  {
    title: "비고",
    dataIndex: "memo",
    key: "memo",
    width: 196,
    render: (_, record) => (
      <CustomColumn>
        {record.jgc_deleted_dttm
          ? `회수 (${moment(record.jgc_deleted_dttm).format("YYYY.MM.DD")})`
          : "-"}
      </CustomColumn>
    ),
  },
];

const commonColumnStyle = `
font-family: "Noto Sans KR-Regular";
font-style: normal;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
justify-content: center;
text-align: center `;

const CustomColumn = styled.div`
  ${commonColumnStyle}
`;
