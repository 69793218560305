import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import * as messageAPI from "../api/messageAPI";
import {
  MODIFY_AUTOMESSAGE_FAILURE,
  MODIFY_AUTOMESSAGE_SUCCESS,
  MODIFY_AUTOMESSAGE,
  GET_AUTOMESSAGE_FAILURE,
  GET_AUTOMESSAGE_SUCCES,
  GET_AUTOMESSAGE,
  GET_MESSAGE_HISTORY,
  GET_MESSAGE_HISTORY_FAILURE,
  GET_MESSAGE_HISTORY_SUCCESS,
  GET_CERTIFICATEDATA,
  GET_CERTIFICATEDATA_SUCCES,
  GET_CERTIFICATEDATA_FAILURE,
  GET_PUSH_NOTIFICATION_HISTORY,
  GET_PUSH_NOTIFICATION_HISTORY_SUCCESS,
  GET_PUSH_NOTIFICATION_HISTORY_FAILURE,
  DELETE_AUTOMESSAGE_SUCCESS,
  DELETE_AUTOMESSAGE_FAILURE,
  DELETE_AUTOMESSAGE,
  CREATE_AUTOMESSAGE,
  CREATE_AUTOMESSAGE_FAILURE,
  CREATE_AUTOMESSAGE_SUCCESS,
} from "../reducers/message";

export default function* messageSaga() {
  function* getCertificateData(action) {
    try {
      const result = yield call(messageAPI.getCertificateData, action.data);
      yield put({
        type: GET_CERTIFICATEDATA_SUCCES,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_CERTIFICATEDATA_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetCertificateData() {
    yield takeLatest(GET_CERTIFICATEDATA, getCertificateData);
  }
  /** 메시지 내역을 가져오는 함수 */
  function* getMessageHistory(action) {
    try {
      const result = yield call(messageAPI.getMessageHistory, action.data);
      yield put({
        type: GET_MESSAGE_HISTORY_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_MESSAGE_HISTORY_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetMessageHistory() {
    yield takeLatest(GET_MESSAGE_HISTORY, getMessageHistory);
  }
  /** 푸시 알림 내역을 가져오는 함수 */
  function* getPushNotificationHistory(action) {
    try {
      const result = yield call(
        messageAPI.getPushNotificationHistory,
        action.data
      );
      yield put({
        type: GET_PUSH_NOTIFICATION_HISTORY_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_PUSH_NOTIFICATION_HISTORY_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetPushNotificationHistory() {
    yield takeLatest(GET_PUSH_NOTIFICATION_HISTORY, getPushNotificationHistory);
  }

  function* getAutoMessageSetting(action) {
    try {
      const result = yield call(messageAPI.getAutoMessageSetting, action.data);
      yield put({
        type: GET_AUTOMESSAGE_SUCCES,
        data: result,
      });
    } catch (err) {
      yield put({
        type: GET_AUTOMESSAGE_FAILURE,
        data: err,
      });
    }
  }
  function* watchGetAutoMessageSetting() {
    yield takeLatest(GET_AUTOMESSAGE, getAutoMessageSetting);
  }

  function* modifyAutoMessageSettig(action) {
    try {
      const result = yield call(
        messageAPI.modifyAutoMessageSettig,
        action.data
      );
      yield put({
        type: MODIFY_AUTOMESSAGE_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: MODIFY_AUTOMESSAGE_FAILURE,
        data: err,
      });
    }
  }
  function* watchModifyAutoMessageSettig() {
    yield takeLatest(MODIFY_AUTOMESSAGE, modifyAutoMessageSettig);
  }

  function* deleteAutoMessageSetting(action) {
    try {
      const result = yield call(
        messageAPI.deleteAutoMessageSetting,
        action.data
      );
      yield put({
        type: DELETE_AUTOMESSAGE_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: DELETE_AUTOMESSAGE_FAILURE,
        data: err,
      });
    }
  }
  function* watchDeleteAutoMessageSetting() {
    yield takeLatest(DELETE_AUTOMESSAGE, deleteAutoMessageSetting);
  }

  function* createAutoMessage(action) {
    try {
      const result = yield call(messageAPI.createAutoMessage, action.data);
      yield put({
        type: CREATE_AUTOMESSAGE_SUCCESS,
        data: result,
      });
    } catch (err) {
      yield put({
        type: CREATE_AUTOMESSAGE_FAILURE,
        data: err,
      });
    }
  }
  function* watchCreateAutoMessage() {
    yield takeLatest(CREATE_AUTOMESSAGE, createAutoMessage);
  }

  yield all([
    fork(watchModifyAutoMessageSettig),
    fork(watchGetAutoMessageSetting),
    fork(watchGetMessageHistory),
    fork(watchGetCertificateData),
    fork(watchGetPushNotificationHistory),
    fork(watchDeleteAutoMessageSetting),
    fork(watchCreateAutoMessage),
  ]);
}
