import axios from "axios";
import { Api } from "./Api";

/*자동문자 설정 가져오는 api */
export const getAutoMessageSetting = async (data) => {
  const response = await axios.get(
    `${Api.ServerJSON.getInstance().BROJ_SERVER}api/jgroup/${
      data.jgroup_key
    }/auto-message`
  );
  return response.data.result;
};
//대여권 최초 구매시
//대여권 재구매 시
//쿠폰 만료
//홀딩 종료 임박
//수강권 만료전
/* 자동문자 인증된 번호 가져오는 api*/
export const getCertificateData = async (data) => {
  const response = await axios.get(
    `${
      Api.ServerJSON.getInstance().BROJ_SMS_NANO_SERVER
    }massage/certificate?jgroup_key=${data.jgroup_key}`
  );
  return response.data.data;
};
/** 메시지 내역을 가져오는 함수
 * @param data moment(date)
 */
export const getMessageHistory = async (data) => {
  let sms = [];
  let lms = [];
  try {
    let response = await axios.get(
      `${
        Api.ServerJSON.getInstance().BROJ_SMS_NANO_SERVER
      }massage/sms?sendDate=${data.date}&TR_ETC1=${data.jgroup_key}`
    );
    sms = [...sms, ...response.data.data];

    /** mms API를 요청하는 양식이지만, 아직 mms쪽 서버 개발이 안된 상태이며, lms 데이터를 넘기는 형태로 되어있습니다.
     * 따라서, 나중에 mms가 만들어지면 그때 이걸 그대로 사용하면 됩니다.
     */
    response = await axios.get(
      `${
        Api.ServerJSON.getInstance().BROJ_SMS_NANO_SERVER
      }massage/mms?sendDate=${data.date}&ETC1=${data.jgroup_key}`
    );
    lms = [...lms, ...response.data.data];
    return { sms, lms };
  } catch (e) {
    console.log(e);
  }
};

export const putSenderNumberAdd = async (data) => {
  try {
    const response = await axios.post(
      `${Api.ServerJSON.getInstance().BROJ_SMS_NANO_SERVER}massage/certificate`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
/** 푸시 알림 내역을 가져오는 함수
 * {
 *  page_size
 *  page_index
 *  started_dttm
 *  finished_dttm
 *  status
 *  keyword
 *  sort_properties
 *  sort_desc
 *  jgroup_key
 * }
 */
export const getPushNotificationHistory = async (form) => {
  try {
    const response = await axios.post(
      `${
        Api.ServerJSON.getInstance().BROJ_ALARM_SERVER
      }push/api/retrieve/pushmjmember/page`,
      form
    );
    return response.data.results;
  } catch (e) {
    console.log(e);
  }
};

/* 자동알림 설정 수정하는 api */
export const modifyAutoMessageSettig = async (data) => {
  try {
    const response = await axios.patch(
      `${Api.ServerJSON.getInstance().BROJ_SERVER}api/jgroup/${
        data.jgroup_key
      }/auto-message/${data.auto_message_key}`,
      data.modifyData
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

export const deleteAutoMessageSetting = async (data) => {
  console.log(data);
  try {
    const response = await axios.delete(
      `${Api.ServerJSON.getInstance().BROJ_SERVER}api/jgroup/${
        data.jgroup_key
      }/auto-message/${data.selectedToggleBtn.auto_message_key}`
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

/**자동알림 설정 생성 api */
export const createAutoMessage = async (data) => {
  try {
    const response = await axios.post(
      `${Api.ServerJSON.getInstance().BROJ_SERVER}api/jgroup/${
        data.jgroup_key
      }/auto-message`,
      data.createAutoMessageData
    );
    return response.data.result;
  } catch (e) {
    console.log(e, e.response);
  }
};

export const patchCallingNumber = async (data) => {
  try {
    const response = await axios.patch(
      `${Api.ServerJSON.getInstance().BROJ_SERVER}api/jgroup/${
        data.jgroup_key
      }/auto-message/default-calling-number/${data.defaultNumber}`
    );
  } catch (e) {
    console.log(e, e.error);
  }
};
