import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Switch, Modal, Dropdown, Menu } from "antd";
import { Icon, Text, Title } from "../styles/commonStyle";
import { CustomButton } from "./CustomButton";
import { deleteAutoMessageSettingAction } from "../reducers/message";
import NoticeModal from "./NoticeModal";
export default function ToggleBtn(props) {
  const {
    key,
    text,
    checked,
    handleToggleBtn,
    settingData,
    toggleStatusJsonList,
    openNoticeModal,
    openModifyModal,
  } = props;

  const [isDefault, setIsDefault] = useState(false);

  const checkDefaultSetting = () => {
    if (settingData.created_type === "DEFAULT") {
      setIsDefault(true);
    }
  };

  const MenuItem = Menu.Item;
  const menuStyle = {
    backgroundColor: "#4a4a4a",
    // fontSize: "14px",
  };

  /**
   * @function handleInputChange Input 컴포넌트에 사용자가 입력을 하면, 입력한 위치로 커서 포지션과 value를 업데이트합니다
   * @function handleClickTag 여러 태그가 있을텐데, 태그를 클릭하면 Input value에 태그를 삽입합니다.
   * */

  const menuItemStyle = {
    background: "none",
    color: "#ffffff",
  };
  const menu = (
    <Menu
      style={menuStyle}
      //   items={[
      //     {
      //       key: "1",
      //       label: (
      //         <p style={{ backgroundColor: "none", color: "#ffffff" }}>
      //           설정 추가
      //         </p>
      //       ),
      //     },
      //     {
      //       key: "2",
      //       label: <p style={{ color: "#ffffff" }}>설정 삭제</p>,
      //     },
      //   ]}
    >
      <MenuItem key={1} style={menuItemStyle}>
        <CustomButton
          text="설정 수정"
          width="54.66px"
          height="22px"
          fontSize="14px"
          // onClick={closeNoticeModal}
          onClick={() => openModifyModal(settingData)}
        />
      </MenuItem>
      {!isDefault && (
        <MenuItem key={2} style={menuItemStyle}>
          <CustomButton
            text="설정 삭제"
            width="54.66px"
            height="22px"
            fontSize="14px"
            onClick={() => openNoticeModal(settingData)}
          />
        </MenuItem>
      )}
    </Menu>
  );

  useEffect(() => {
    if (settingData) {
      checkDefaultSetting();
    }
  }, []);

  return (
    <div
      className="toggle-btn"
      //   onMouseEnter={showModal}
      //   onMouseLeave={hideModal}
      style={{
        maxWidth: "480px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "5px",
        padding: "12px",
        background: "#4A4A4A",
        // flex: "0 0 33.33%",
      }}
    >
      {/* <DragBtn
      horverCursor={isOrderChange ? "pointer" : null}
      disabled={isOrderChange ? false : true}
      onClick={clicked}
    > */}
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <span
          style={{
            marginLeft: "10px",
            fontSize: "14px",
            color: "#ffffff",
          }}
        >
          {text ?? ""}
        </span>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Switch
          checked={checked && checked.length > 0 && checked[0].status}
          onChange={() => handleToggleBtn(settingData, checked)}
        />
        <Dropdown overlay={menu}>
          <span
            style={{
              padding: "12px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            {/* <CustomBtn onClick={() => console.log("!")}> */}
            {/* <Dropdown menu={{ dropDownItems }}> */}
            <Icon
              width="18px"
              // height="5px"
              src={process.env.PUBLIC_URL + "/assets/images/icon/more_icon.png"}
            />
            {/* </Dropdown> */}
            {/* </CustomBtn> */}
          </span>
        </Dropdown>
      </div>
    </div>
  );
}

const CustomBtn = styled.button`
  /* min-width: 380px; */
  height: ${(props) => (props.height ? props.height : null)};
  background: #4a4a4a;
  border: ${(props) => (props.border ? props.border : "none")};
  /* border-radius: 5px; */
  /* padding: 12px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  :hover {
    cursor: ${(props) => (props.horverCursor ? props.horverCursor : "pointer")};
  }
`;
// style={{
//     minWidth: "380px",
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-between",
//     alignItems: "center",
//     borderRadius: "5px",
//     padding: "12px",
//     background: "#4A4A4A",
//   }}
