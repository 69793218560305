import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

export const AlertModal = ({
  width,
  height,
  borderRadius,
  background,
  color,
  fontSize,
  padding,
  text,
  zIndex,
}) => {
  return (
    <Alert
      width={width}
      height={height}
      borderRadius={borderRadius}
      background={background}
      color={color}
      fontSize={fontSize}
      zIndex={zIndex}
      padding={padding}
    >
      {text ? text : "텍스트를 입력해주세요"}
    </Alert>
  );
};

const fadeIn = keyframes`
  0% {
    transform-origin: 0 0;
    transform: translateY(100%);
    opacity: 0;
  }
  100%{
    transform-origin: 0 0;
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    transform-origin: 0 0;
    transform: translateY(0);
    opacity: 1;
  }
  100%{
    transform-origin: 0 0;
    transform: translateY(100%);
    opacity: 0;
  }
`;

const Alert = styled.div`
  z-index: ${(props) => (props.zIndex ? props.zIndex : 100000)};
  min-width: ${(props) => (props.width ? `${props.width}` : "250px")};
  height: ${(props) => (props.height ? `${props.height}` : "57.5px")};
  border-radius: ${(props) =>
    props.borderRadius ? `${props.borderRadius}` : "2px"};
  background: ${(props) =>
    props.background ? `${props.background}` : "rgba(0,0,0,0.8)"};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}` : "17px")};
  color: ${(props) => (props.color ? `${props.color}` : "#fff")};
  padding: ${(props) => (props.padding ? `${props.padding}` : "16px")};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 40%;
  bottom: 30px;
  animation: ${fadeIn} 0.5s, ${fadeOut} 0.5s 2s;
  animation-fill-mode: forwards;
`;
