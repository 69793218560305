import { Checkbox, Divider, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  getCrmProductAction,
  getMarketProductAction,
} from "../reducers/coupon";
import { Spacer, Text } from "../styles/commonStyle";
import "../styles/SelectProductMenu.css";
import CustomSpinner from "./CustomSpinner";

/** 선택 상품 SELECT 버튼을 클릭했을 때, 나오는 상품 선택 모달창 */
const SelectProductMenu = ({
  formRef,
  type,
  selectedMarketProduct,
  handleSelectedMarketProduct,
  isNotCreate,
}) => {
  const [checkedList, setCheckedList] = useState([]);
  const containerRef = useRef(null);
  const marketProductList = useSelector(
    (state) => state.couponReducer.marketProductList
  );
  const [isSelect, setIsSelect] = useState(false);
  const [productList, setProductList] = useState(null);
  const [productTicket, setProductTicket] = useState(null);
  const [productLesson, setProductLesson] = useState(null);
  const [productLocker, setProductLocker] = useState(null);
  const [productSports, setProductSports] = useState(null);
  const [productNormal, setProductNormal] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState([]);
  const [selectedLocker, setSelectedLocker] = useState([]);
  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedNormal, setSelectedNormal] = useState([]);

  const handleCheckAll = (e) => {
    if (!isSelect) {
      setIsSelect((isSelect) => !isSelect);
    }
    if (e.target.checked) {
      switch (type) {
        case "TICKET_MEMBER":
          setSelectedTicket(productTicket);
          break;
        case "TICKET_LESSON":
          setSelectedLesson(productLesson);
          break;
      }
    } else {
      switch (type) {
        case "TICKET_MEMBER":
          setSelectedTicket([]);
          break;
        case "TICKET_LESSON":
          setSelectedLesson([]);
          break;
      }
    }
  };

  const handleCheckItem = (item) => {
    if (!isSelect) {
      setIsSelect((isSelect) => !isSelect);
    }
    switch (type) {
      case "TICKET_MEMBER":
        setSelectedTicket((prevCheckedList) => {
          const isChecked = prevCheckedList.find(
            (product) => product.product_key === item.product_key
          );
          if (isChecked) {
            return prevCheckedList.filter(
              (prev) => prev.product_key !== item.product_key
            );
          } else {
            return [...prevCheckedList, item];
          }
        });
        break;
      case "TICKET_LESSON":
        setSelectedLesson((prevCheckedList) => {
          const isChecked = prevCheckedList.find(
            (product) => product.product_key === item.product_key
          );
          if (isChecked) {
            return prevCheckedList.filter(
              (prev) => prev.product_key !== item.product_key
            );
          } else {
            return [...prevCheckedList, item];
          }
        });
        break;
    }
  };

  /** 이용권 상품들 및 수강권 상품들 등의 정보를 필터링 하여 정보 저장 */
  useEffect(() => {
    setProductTicket(
      marketProductList.filter(
        (product) =>
          product.product_kind === "BRAND_TICKET_MEMBER" ||
          product.product_kind === "CRM_TICKET_MEMBER"
      )
    );
    setProductLesson(
      marketProductList.filter(
        (product) =>
          product.product_kind === "BRAND_TICKET_LESSON" ||
          product.product_kind === "CRM_TICKET_LESSON"
      )
    );

    if (isNotCreate) {
      setSelectedTicket(
        selectedMarketProduct.filter(
          (product) =>
            product.product_kind === "BRAND_TICKET_MEMBER" ||
            product.product_kind === "CRM_TICKET_MEMBER"
        )
      );
      setSelectedLesson(
        selectedMarketProduct.filter(
          (product) =>
            product.product_kind === "BRAND_TICKET_LESSON" ||
            product.product_kind === "CRM_TICKET_LESSON"
        )
      );
    }
  }, []);

  /** 선택된 이용권, 수강권 등의 상태 업데이트 시 CheckedList의 상태를 업데이트 */
  useEffect(() => {
    setCheckedList([
      ...selectedTicket,
      ...selectedLesson,
      ...selectedLocker,
      ...selectedSports,
      ...selectedNormal,
    ]);
  }, [
    selectedTicket,
    selectedLesson,
    selectedLocker,
    selectedSports,
    selectedNormal,
  ]);

  /** 체크박스에 체크된 리스트 정보를 폼에 전달 */
  useEffect(() => {
    if (isNotCreate) {
      if (isSelect) {
        handleSelectedMarketProduct(checkedList);
      }
    } else {
      handleSelectedMarketProduct(checkedList);
    }
  }, [checkedList]);

  /** 수강권, 이용권 등의 탭이 변경되면 productList 상태를 업데이트 */
  useEffect(() => {
    switch (type) {
      case "TICKET_MEMBER":
        setProductList(
          marketProductList.filter(
            (product) =>
              product.product_kind === "BRAND_TICKET_MEMBER" ||
              product.product_kind === "CRM_TICKET_MEMBER"
          )
        );
        break;

      case "TICKET_LESSON":
        setProductList(
          marketProductList.filter(
            (product) =>
              product.product_kind === "BRAND_TICKET_LESSON" ||
              product.product_kind === "CRM_TICKET_LESSON"
          )
        );
        break;

      default:
        break;
    }
  }, [type]);

  useEffect(() => {
    let container = containerRef.current;
    if (container) {
      const handleMouseEnter = () => {
        formRef.current.style.overflow = "hidden";
      };
      const handleMouseLeave = () => {
        formRef.current.style.overflow = "auto";
      };
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  });

  if (!productList) return;
  <SpinContainer>
    <Spin size="large" />
  </SpinContainer>;

  return (
    <React.Fragment>
      {productList && (
        <MenuContainer className="selectProductMenu">
          <Checkbox
            checked={(() => {
              switch (type) {
                case "TICKET_MEMBER":
                  return selectedTicket.length === productTicket.length;
                case "TICKET_LESSON":
                  return selectedLesson.length === productLesson.length;
              }
            })()}
            onChange={handleCheckAll}
            style={{ fontWeight: 700 }}
          >
            전체 선택
          </Checkbox>
          <Divider style={{ border: "1px solid #7c7c7c" }} />
          <div
            style={{
              height: "124px",
            }}
            ref={containerRef}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                overflow: "auto",
              }}
            >
              {productList.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ paddingBottom: "12px", fontSize: "12px" }}
                  >
                    <Item>
                      <Checkbox
                        checked={(() => {
                          switch (type) {
                            case "TICKET_MEMBER":
                              return selectedTicket.find(
                                (product) =>
                                  product.product_key === item.product_key
                              );
                            case "TICKET_LESSON":
                              return selectedLesson.find(
                                (product) =>
                                  product.product_key === item.product_key
                              );
                          }
                        })()}
                        onChange={() => handleCheckItem(item)}
                      >
                        {item.product_name}
                      </Checkbox>
                      <PriceContainer>
                        <PriceInfo>카드가</PriceInfo>
                        <Spacer size={3} />
                        <Price>{`${item.product_price}원`}</Price>
                      </PriceContainer>
                    </Item>
                  </div>
                );
              })}{" "}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              height: "42px",
              alignItems: "center",
            }}
          >
            <Text>총 {checkedList.length} 건 선택</Text>
          </div>
        </MenuContainer>
      )}
    </React.Fragment>
  );
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* Set the height of the component wrapper to be the height of the tabPane minus the height of the tabs */
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PriceContainer = styled.div`
  display: flex;
`;

const PriceInfo = styled.span`
  font-family: "Noto Sans KR-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  color: #a4a4a4 !important;
`;

const Price = styled.span`
  font-family: "Noto Sans KR-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
`;

const SpinContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default SelectProductMenu;
