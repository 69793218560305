import { Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomButton } from "./CustomButton";
import MessagePreviewModal from "./MessagePreviewModal";

const MessageContentColumn = ({ record }) => {
  const [showButton, setShowButton] = useState(false);
  const [isCoupon, setIsCoupon] = useState(() => {
    if (record.jgcp_reason) {
      return true;
    }
    return false;
  });
  const [mbClicked, setMbClicked] = useState(false);
  const handleMouseEnter = () => {
    setShowButton(true);
  };
  const [message, setMessage] = useState(() => {
    let content =
      record.TR_MSG || record.MSG || record.body || record.jgcp_reason;
    if (content && content.length !== 0) {
      return content;
    }
    return "-";
  });

  const handleMouseLeave = () => {
    setShowButton(false);
  };
  /** 쿠폰 수정 모달창을 여는 함수 */
  const openPreviewModal = () => {
    document.body.style.overflow = "hidden";
    setMbClicked((mbClicked) => !mbClicked);
  };
  /** 쿠폰 수정 모달창을 여는 함수 */
  const closePreviewModal = () => {
    document.body.style.overflow = "unset";
    setShowButton(false);
    setMbClicked((mbClicked) => !mbClicked);
  };

  useEffect(() => {
    setMessage(() => {
      let content =
        record.TR_MSG || record.MSG || record.body || record.jgcp_reason;
      if (content && content.length !== 0) {
        return content;
      }
      return "-";
    });
  }, [record]);

  return (
    <CustomColumn
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {message && message.length > 20 ? `${message.substr(0, 19)}...` : message}
      {message && showButton && (
        <CustomButton
          onClick={openPreviewModal}
          icon={
            <Icon
              src={
                process.env.PUBLIC_URL + "/assets/images/icon/white/edit.png"
              }
            />
          }
          position="absolute"
          width="70px"
          height="28px"
          borderRadius="5px"
          background="#4a4a4a"
          text="내용 확인"
          fontSize="12px"
        />
      )}
      {message && mbClicked && (
        <MessagePreviewModal
          handleChange={closePreviewModal}
          message={message}
          isCoupon={isCoupon}
        />
      )}
    </CustomColumn>
  );
};

const commonColumnStyle = `
font-family: "Noto Sans KR-Regular";
font-style: normal;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
justify-content: center;
text-align: center; `;

const CustomColumn = styled.div`
  ${commonColumnStyle}
`;

export default MessageContentColumn;
