import React from "react";
import { Text } from "../styles/commonStyle";

export default function AutomaticMessageNameLabel({ function_type }) {
  return (
    <div
      style={{
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {function_type === "ACTIVE_CUSTOMER_ALARM" ? (
        <>
          <Text>
            *활성 회원은{" "}
            <Text style={{ textDecoration: "underLine" }} color="#FA6400">
              현재 이용권을 사용중
            </Text>
            이거나{" "}
            <Text style={{ textDecoration: "underLine" }} color="#FA6400">
              사용예정인{" "}
            </Text>
            사람입니다.
          </Text>
          <Text>
            *사용중이신{" "}
            <Text style={{ textDecoration: "underLine" }} color="#FA6400">
              이용권(회원권, 수강권)이 1개라도 있을 경우 활성 회원
            </Text>
            입니다.
          </Text>
          <Text>
            *
            <Text style={{ textDecoration: "underLine" }} color="#FA6400">
              전송시간 기준 활성 회원
            </Text>
            에게 자동알림이 보내집니다.
          </Text>
        </>
      ) : (
        <>
          <Text>
            *비활성 회원은{" "}
            <Text style={{ textDecoration: "underLine" }} color="#FA6400">
              이용권이 만료{" "}
            </Text>
            되었거나{" "}
            <Text style={{ textDecoration: "underLine" }} color="#FA6400">
              보유하고 있지 않은{" "}
            </Text>
            사람입니다.
          </Text>
          <Text>
            *사용중이신{" "}
            <Text style={{ textDecoration: "underLine" }} color="#FA6400">
              이용권(회원권, 수강권)이 1개라도 있을 경우 활성 회원
            </Text>
            입니다.
          </Text>
          <Text>
            *
            <Text style={{ textDecoration: "underLine" }} color="#FA6400">
              전송시간 기준 비활성 회원
            </Text>
            에게 자동알림이 보내집니다.
          </Text>
        </>
      )}
    </div>
  );
}
