import { Layout } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CouponManagement from "../components/CouponManagement";
import CouponTable from "../components/CouponTable";
import CouponSearchBar from "../components/CouponSearchBar";
import { getCouponAction, getMarketProductAction } from "../reducers/coupon";
import { Spacer } from "../styles/commonStyle";

/** 쿠폰을 전체적으로 관리하는 메인 페이지 */
const CouponManage = (props) => {
  const { jgroup_key_cookie } = props;
  const jgroupKey = useSelector(
    (state) => state.groupReducer.jgroup_key_inCookie
  );
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(null);
  const couponList = useSelector((state) => state.couponReducer.couponList);
  const [filteredCouponList, setFilteredCouponList] = useState(null);
  const [height, setHeight] = useState(0);
  const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectedRows = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const initSelectedRows = () => {
    handleSelectedRows([]);
    handleSelectedRowsKeys([]);
  };

  const handleSelectedRowsKeys = (selectedRowsKeys) => {
    setSelectedRowsKeys(selectedRowsKeys);
  };

  useEffect(() => {
    let tableHeight = document.getElementById("coupon_table").clientHeight;
    setHeight(tableHeight - 44);
    const updateHeight = () => {
      tableHeight = document.getElementById("coupon_table").clientHeight;
      setHeight(tableHeight - 44);
    };
    window.addEventListener("resize", updateHeight);
    dispatch(getCouponAction({ jgroup_key: jgroupKey }));
    dispatch(getMarketProductAction({ jgroup_key: jgroupKey }));
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (filter) {
      setFilteredCouponList(
        couponList.filter((coupon) => coupon.jgcp_name.trim().includes(filter))
      );
    } else {
      setFilteredCouponList(couponList);
    }
  }, [filter, couponList]);

  return (
    <Layout style={{ maxHeight: "calc(100% - 90px)" }}>
      <Header>
        <CouponSearchBar
          filteredCouponList={filteredCouponList}
          setFilter={setFilter}
          couponList={couponList}
        />
        <CouponManagement
          selectedRows={selectedRows}
          initSelectedRows={initSelectedRows}
        />
      </Header>
      <Spacer />
      <Content id="coupon_table">
        <CouponTable
          filteredCouponList={filteredCouponList}
          couponList={couponList}
          selectedRowsKeys={selectedRowsKeys}
          handleSelectedRowsKeys={handleSelectedRowsKeys}
          handleSelectedRows={handleSelectedRows}
          height={height}
        />
      </Content>
    </Layout>
  );
};

export default CouponManage;
