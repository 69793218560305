import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "./Api";
const env = process.env.NODE_ENV || "development";
const config = require("../config/config.json")[env];

export const checkRefreshTokenAPI = async (data) => {
  // const response = await axios.get(
  //   `${
  //     Api.ServerJSON.getInstance().BROJ_SERVER
  //   }joauth/check/refreshtoken?refresh_token=${data.accessToken}`
  // );
  //   if (response.data.result.status === "coincide") {
  //     axios.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
  //     const accessRespones = await axios.post(
  //       `${
  //         Api.ServerJSON.getInstance().BROJ_SERVER
  //       }joauth/token/renewal?refresh_token=${data.accessToken}`
  //     );
  //     return {
  //       token: accessRespones?.data.result,
  //     };
  //   } else {
  //     return {
  //       token: null,
  //     };
  //   }
  const checkExpireToken = (exp) => {
    if (exp * 1000 < Date.now() + 10 * 1000) {
      // console.log(
      //   '만료!',
      //   new Date(exp * 1000),
      //   new Date(Date.now() + 10 * 1000)
      // );
      return true;
    } else {
      // console.log(
      //   '유효!',
      //   new Date(exp * 1000),
      //   new Date(Date.now() + 10 * 1000)
      // );
      return false;
    }
  };

  const base64Url = data.accessToken.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  // return JSON.parse(Base64.atob(base64));
  const tokenData = JSON.parse(window.atob(base64));
  if (tokenData.exp && checkExpireToken(tokenData) === false) {
    return {
      token: data.accessToken,
    };
  } else {
    return {
      token: null,
    };
  }
};

//get server json
export const getServerJSON = async () => {
  // const response = await axios.get(config.url + "/BroJServer/api/broj/json");

  return response;
};
