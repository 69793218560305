// reducers.js
import { combineReducers } from "redux";
import couponReducer from "./coupon";
import messageReducer from "./message";
import groupReducer from "./group";
import oauthReducer from "./oauth";


/** Project내 모든 Reducer를 결합하는 RootReducer */
const rootReducer = combineReducers({
  oauthReducer,
  couponReducer,
  messageReducer,
  groupReducer,
});

export default rootReducer;
