import cookie from "react-cookies";
import produce from "immer";
import axios from "axios";
import { Api } from "../api/Api";

export const initialState = {
  loadServerJSONLoading: false,
  loadServerJSONDone: false,
  loadServerJSONError: false,

  checkTokenLoading: false,
  checkTokenDone: false,
  checkTokenError: false,

  accessToken: null,
  refreshToken: null,
  serverJSON: null,
  memberId: null,
  memberKey: null,
};
//action 이름
export const LOAD_SERVER_JSON_REQUEST = "LOAD_SERVER_JSON_REQUEST";
export const LOAD_SERVER_JSON_SUCCESS = "LOAD_SERVER_JSON_SUCCESS";
export const LOAD_SERVER_JSON_FAILURE = "LOAD_SERVER_JSON_FAILURE";

export const CHECK_TOKEN_REQUEST = "CHECK_TOKEN_REQUEST";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_FAILURE = "CHECK_TOKEN_FAILURE";

//action 등록
export const loadServerJSONRequest = (data) => ({
  type: LOAD_SERVER_JSON_REQUEST,
  data,
});

export const checkTokenRequest = (data) => ({
  type: CHECK_TOKEN_REQUEST,
  data,
});

const oauthReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOAD_SERVER_JSON_REQUEST:
        draft.loadServerJSONDone = false;
        draft.loadServerJSONError = null;
        draft.loadServerJSONLoading = true;
        break;
      case LOAD_SERVER_JSON_SUCCESS:
        draft.loadServerJSONDone = true;
        draft.loadServerJSONLoading = false;
        draft.serverJSON = action.data;
        break;
      case LOAD_SERVER_JSON_FAILURE:
        draft.loadServerJSONLoading = false;
        draft.loadServerJSONError = action.error;
        break;
      case CHECK_TOKEN_REQUEST:
        draft.checkTokenDone = false;
        draft.checkTokenError = null;
        draft.checkTokenLoading = true;
        break;
      case CHECK_TOKEN_SUCCESS:
        draft.serverJSON = action.data.serverJSON;
        draft.checkTokenLoading = false;
        // console.log("!!", action.data);
        if (action.data.accessToken) {
          axios.defaults.headers.Authorization = `Bearer ${action.data.accessToken.token}`;
        } else {
        }
        draft.checkTokenDone = true;
        draft.checkTokenError = false;
        break;
      case CHECK_TOKEN_FAILURE:
        draft.checkTokenLoading = false;
        draft.checkTokenError = true;
        break;
      default:
        // return state;
        break;
    }
  });
};

export default oauthReducer;
