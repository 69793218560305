import { height } from "@mui/system";
import { Button } from "antd";
import styled from "styled-components";
import { commonTheme } from "./theme";

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const Icon = styled.img`
  height: 100%;
  object-fit: contain;
`;

export const Spacer = styled.div`
  padding: ${(props) => (props.size ? `${props.size}px` : "6px")};
`;

export const ModalScreen = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 10001)};
  background: #131313a3;
`;

export const PositionController = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span`
  font-family: ${(props) =>
    props.fontFamily ? `${props.fontFamily}` : "Noto Sans KR-Regular"};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.size ? `${props.size}px` : "14px")};
  color: ${(props) =>
    props.color != null ? props.color : "#ffffff"} !important;
  line-height: ${(props) =>
    props.lineHeight ? `${props.lineHeight}px` : "20px"};
`;

export const Title = styled.p`
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  color: ${(props) => (props.color ? props.color : commonTheme.font.black5)};
`;

export const ActionButtonGroup = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  z-index: 11000;
  justify-content: center;
`;

export const CloseButton = styled.button`
  border: none;
  background: none;
  :hover {
    cursor: pointer;
  }
`;
