import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import QueryString from "qs";
import { Tabs } from "antd";
import cookie from "react-cookies";
import styled from "styled-components";
import CouponManage from "./pages/CouponManage";
import MessageHistory from "./pages/MessageHistory";
import MessageTransfer from "./pages/MessageTransfer";
import { commonTheme } from "./styles/theme";
import "antd/dist/antd.min.css";
import "./styles/globals.css";

import {
  CHECK_TOKEN_REQUEST,
  LOAD_SERVER_JSON_REQUEST,
} from "./reducers/oauth";
import { getJgroupKey } from "./reducers/group";
import { Api } from "./api/Api";

const env = process.env.REACT_APP_ENVIRONMENT;

// console.log(
//   "process.env.REACT_APP_ENVIRONMENT",
//   process.env.REACT_APP_ENVIRONMENT
// );
const config = require("./config/config.json")[env];
const { TabPane } = Tabs;

const tabs = {
  url: ["/", "/messageHistory", "/couponManage"],
  title: ["메세지 전송", "메세지 내역", "쿠폰 관리"],
};

function App() {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.oauthReducer.accessToken);
  const { serverJSON, checkTokenError } = useSelector(
    (state) => state.oauthReducer
  );

  const jgroupKey = useSelector(
    (state) => state.groupReducer.jgroup_key_inCookie
  );
  const [tabNumber, setTabNumber] = useState(0); // 몇번째 탭인지 알려주는 변수
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const searchString = queryParams.get("search");
  const navigate = useNavigate();
  const changeTab = (index) => {
    // 탭의 변경을 알려주는 함수
    setTabNumber(index);
    navigate(tabs.url[index]);
  };

  useEffect(() => {
    async function serverInit() {
      try {
        await Api.ServerJSON.init(config);
      } catch (e) {
        console.log(e);
      }
    }

    async function setAuth() {
      const queryData = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      // console.log('!!',queryData.refresh_token)
      // console.log("access_token", queryData.access_token);

      // const refresh_token = cookie.load("refresh_token");
      // console.log("refresh_token", refresh_token);
      // if (queryData.refresh_token) {
      // if(refresh_token){
      dispatch({
        type: CHECK_TOKEN_REQUEST,
        data: {
          accessToken: queryData.access_token,
          // refreshToken: refresh_token,
        },
      });
      // }
      // else {
      // cookie.remove("accessToken", {
      //   path: "/",
      //   domain: Api.ServerJSON.getInstance().DOMAIN,
      // });
      // cookie.remove("refreshToken", {
      //   path: "/",
      //   domain: Api.ServerJSON.getInstance().DOMAIN,
      // });
      // cookie.remove("jgroup_key", {
      //   path: "/",
      //   domain: Api.ServerJSON.getInstance().DOMAIN,
      // });
      // cookie.remove("trainer_key", {
      //   path: "/",
      //   domain: Api.ServerJSON.getInstance().DOMAIN,
      // });
      // console.log("**");

      // window.parent.postMessage({
      //   "message": "none_token"
      // },"*")
      // window.location.href =
      //   Api.ServerJSON.getInstance().BROJ_JOUATH_URL +
      //   Api.ServerJSON.getInstance().LOGIN_PARAM;
      // }
    }

    async function setJgroup() {
      const queryData = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      // console.log("!!", queryData.jgroup_key);
      dispatch(getJgroupKey(queryData.jgroup_key));
    }

    async function init() {
      await serverInit();
      await setAuth();
      setJgroup();
      changeTab(0);
    }

    init();
  }, []);

  const jgroup_key_cookie = cookie.load("jgroup_key");

  useEffect(() => {
    if (jgroupKey) {
    }
  }, [jgroupKey]);
  return checkTokenError ? (
    <Background>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1 style={{ color: "#ffffff" }}>
          유효하지 않은 인증 정보입니다. 새로고침 후 이용해 주세요.
        </h1>
      </div>
    </Background>
  ) : (
    <Background>
      {/* <PrevLocation>{"홈 > 메세지 > "}</PrevLocation> */}
      {/* <CurLocation>{tabs.title[tabNumber]}</CurLocation> */}
      <Spacer />
      <Tabs type="card" onChange={changeTab} defaultActiveKey="0">
        {tabs.title.map((tab, index) => {
          return <TabPane tab={tab} key={index} style={{ fontWeight: 700 }} />;
        })}
      </Tabs>
      <Routes>
        <Route
          exact
          path="/"
          element={<MessageTransfer jgroup_key_cookie={jgroup_key_cookie} />}
        />
        <Route
          path="/messageHistory"
          element={<MessageHistory jgroup_key_cookie={jgroup_key_cookie} />}
        />
        <Route
          path="/couponManage"
          element={<CouponManage jgroup_key_cookie={jgroup_key_cookie} />}
        />
      </Routes>
    </Background>
  );
}

const Background = styled.div`
  padding: 15px;
  width: 100%;
  height: 100vh;
  background-color: ${commonTheme.background.black2};
  overflow: auto;
`;

// 준에게 컨펌 받아야합니다. 디자인된 부분에선 #aeaeae인데 폰트 색상 심볼에는(유사한 색 #a4a4a4) 없어요.
const PrevLocation = styled.span`
  color: ${commonTheme.font.black3};
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  font-style: normal;
`;

const CurLocation = styled.span`
  color: ${commonTheme.font.black5};
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  font-style: normal;
`;

const Spacer = styled.div`
  height: ${(props) => (props.size != null ? `${props.size}px` : `15px`)};
`;

export default App;
