import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getGoodsByKeysAction } from "../reducers/coupon";
import CouponProduct from "./CouponProduct";
import CustomSpinner from "./CustomSpinner";

export const ProductColumn = ({ record }) => {
  const [pbClicked, setPbClicked] = useState(false);
  const marketProductList = useSelector(
    (state) => state.couponReducer.marketProductList
  );
  const [goodsList, setGoodsList] = useState([]);

  /** 쿠폰 수정 모달창을 여는 함수 */
  const openProductListModal = () => {
    document.body.style.overflow = "hidden";
    setPbClicked((pbClicked) => !pbClicked);
  };
  /** 쿠폰 수정 모달창을 여는 함수 */
  const closeProductListModal = () => {
    document.body.style.overflow = "unset";
    setPbClicked((pbClicked) => !pbClicked);
  };

  useEffect(() => {
    let goodsArr = [];
    if (marketProductList) {
      switch (record.available_type) {
        case "LIMITED":
          if (record.available_products) {
            record.available_products.map((goods) =>
              marketProductList.filter(
                (product) =>
                  product.goods_key === goods && goodsArr.push(product)
              )
            );
          }

          break;
        case "MARKET_TICKET_UNLIMITED":
          goodsArr = marketProductList;
          break;
        // case "CRM_UNLIMITED":
        //   goodsArr = marketProductList;
        //   break;
        // case "CRM_MARKET_TICKET_UNLIMITED":
        //   goodsArr = marketProductList;
        //   break;

        default:
          break;
      }
    }
    setGoodsList(goodsArr);
  }, [pbClicked]);
  return (
    <CouponHistoryColumn>
      <ProductButton onClick={openProductListModal}>보기</ProductButton>
      {pbClicked && (
        <CouponProduct
          tableData={goodsList}
          handleChange={closeProductListModal}
        />
      )}
    </CouponHistoryColumn>
  );
};

const ProductButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  border-radius: 16px;
  font-size: 12px;
  line-height: 17px;
  background: #4a4a4a;
  color: #fff;
  height: 32px;
  width: 71px;
  :hover {
    cursor: pointer;
  }
`;

const CouponHistoryColumn = styled.div`
  font-family: "Noto Sans KR-Regular";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
