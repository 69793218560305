import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Select, Row, Col, Modal, Button } from "antd";
import { Icon, Text, Title } from "../styles/commonStyle";
import ToggleBtn from "./ToggleBtn";
import { useDispatch, useSelector } from "react-redux";
import MessageSettingModal from "./MessageSettingModal";
import AddSenderNumberModal from "./AddSenderNumberModal";
import {
  deleteAutoMessageSettingAction,
  modifyAutoMessageSettigAction,
} from "../reducers/message";
import NoticeModal from "./NoticeModal";
import CustomSpinner from "./CustomSpinner";
import { patchCallingNumber } from "../api/messageAPI";
import { getJgroupAction } from "../reducers/group";

export default function AutoMaticMessage(props) {
  const dispatch = useDispatch();
  const { jgroupData, certificateDataList, selectedNumber, setSelectedNumber } =
    props;
  const jgroupKey = useSelector(
    (state) => state.groupReducer.jgroup_key_inCookie
  );

  const [isSetAddModalOpen, setIsSetAddModalOpen] = useState(false);
  const [isAddSenderNumModalOpen, setIsAddSenderNumModalOpen] = useState(false);
  const [dbClicked, setDbClicked] = useState(false); //(d)elete(b)utton
  const [mbClicked, setMbClicked] = useState(false); //(m)odify(b)utton
  const [selectedToggleBtn, setSelectedToggleBtn] = useState(null);
  const [toggleStatusJsonList, setToggleStatusJsonList] = useState([]);
  const [selectedSenderNumber, setSelectedSenderNumber] = useState(null);
  const [isSenderNumberChangeModal, setIsSenderNumberChangeModal] =
    useState(false);
  const [isChangeCompleteModal, setIsChangeCompleteModal] = useState(false);
  const customerRelativeSettingList = useSelector(
    (state) => state.messageReducer.customerRelativeSettingList
  );
  const productRelativeSettingList = useSelector(
    (state) => state.messageReducer.productRelativeSettingList
  );
  const deleteAutoMessageLoading = useSelector(
    (state) => state.messageReducer.deleteAutoMessageLoading
  );

  useEffect(() => {
    let _toggleStatusJsonList = [
      ...customerRelativeSettingList,
      ...productRelativeSettingList,
    ];

    _toggleStatusJsonList = _toggleStatusJsonList.map((data) => {
      return {
        auto_message_key: data.auto_message_key,
        status: data.status === "DISABLED" ? false : true,
      };
    });

    setToggleStatusJsonList([..._toggleStatusJsonList]);
  }, [customerRelativeSettingList, productRelativeSettingList]);

  /*발신번호 바꾸는 함수*/
  const senderNumberChange = (value) => {
    if (value === "발신번호 추가") {
      document.body.style.overflow = "hidden";
      setIsAddSenderNumModalOpen(true);
      return;
    }
    setSelectedSenderNumber(value);
    setIsSenderNumberChangeModal(true);
    // setSelectedNumber(value);
  };

  const closeAddSenderNumberModal = () => {
    setIsAddSenderNumModalOpen(false);
  };

  const handleAddSenderNumber = () => {};

  const handleSelectedToggleBtn = (data) => {
    setSelectedToggleBtn(data);
  };

  const showSetAddModal = () => {
    document.body.style.overflow = "hidden";
    setIsSetAddModalOpen(true);
  };

  const closeSetAddModal = () => {
    document.body.style.overflow = "unset";
    setIsSetAddModalOpen(false);
  };
  const openNoticeModal = (settingData) => {
    setSelectedToggleBtn(settingData);
    setDbClicked((dbClicked) => !dbClicked);
  };
  const closeNoticeModal = () => {
    setSelectedToggleBtn(null);
    setDbClicked((dbClicked) => !dbClicked);
    // dispatch(deleteAutoMessageSettingAction);
  };

  const openModifyModal = (settingData) => {
    document.body.style.overflow = "hidden";
    setSelectedToggleBtn(settingData);
    setMbClicked((mbClicked) => !mbClicked);
  };

  const closeModifyModal = () => {
    document.body.style.overflow = "unset";
    setSelectedToggleBtn(null);
    setMbClicked((mbClicked) => !mbClicked);
  };

  const handleUseAllBtn = () => {
    let allCheckedToggleList;
    let allSettingData = [
      ...customerRelativeSettingList,
      ...productRelativeSettingList,
    ];
    if (!toggleStatusJsonList.find((toggle) => toggle.status === false)) {
      allCheckedToggleList = toggleStatusJsonList.map((toggle) =>
        Object.assign(toggle, { status: false })
      );
    } else {
      allCheckedToggleList = toggleStatusJsonList.map((toggle) =>
        Object.assign(toggle, { status: true })
      );
    }
    allSettingData.map((settingData) => {
      let modifyData = {
        name: settingData.name,
        title: settingData.title,
        body: settingData.body,
        function_type: settingData.function_type,
        auto_message_type_list: settingData.auto_message_type_list,
        entry_list: settingData.entry_list,
        status: allCheckedToggleList[0].status ? "ENABLED" : "DISABLED",
        calling_number: settingData.calling_number,
        has_advertisement: settingData.has_advertisement,
      };
      dispatch(
        modifyAutoMessageSettigAction({
          modifyData: modifyData,
          auto_message_key: settingData.auto_message_key,
          jgroup_key: jgroupKey,
        })
      );
    });

    setToggleStatusJsonList([...allCheckedToggleList]);
  };

  const handleToggleBtn = (settingData, checked) => {
    let modifyData = {
      name: settingData.name,
      title: settingData.title,
      body: settingData.body,
      function_type: settingData.function_type,
      auto_message_type_list: settingData.auto_message_type_list,
      entry_list: settingData.entry_list,
      status: settingData.status,
      calling_number: settingData.calling_number,
      coupon_parent_key: settingData.coupon_parent_key,
      has_advertisement: settingData.has_advertisement,
    };

    let _toggleStatusJsonList = toggleStatusJsonList.map((data) => {
      return {
        auto_message_key: data.auto_message_key,
        status:
          data.auto_message_key === checked[0].auto_message_key
            ? !data.status
            : data.status,
      };
    });

    if (!checked[0].status) {
      modifyData.status = "ENABLED";
    } else {
      modifyData.status = "DISABLED";
    }

    dispatch(
      modifyAutoMessageSettigAction({
        modifyData: modifyData,
        auto_message_key: checked[0].auto_message_key,
        jgroup_key: jgroupKey,
      })
    );

    setToggleStatusJsonList([..._toggleStatusJsonList]);
  };

  const deleteAutoMessageSetting = () => {
    dispatch(
      deleteAutoMessageSettingAction({
        selectedToggleBtn: selectedToggleBtn,
        jgroup_key: jgroupKey,
      })
    );
  };

  return (
    <Container>
      {deleteAutoMessageLoading && <CustomSpinner />}
      {dbClicked && selectedToggleBtn && (
        <NoticeModal
          multipleScreen={true}
          multipleButton={true}
          title="설정 삭제"
          secondTitle="설정 삭제 완료"
          headerIcon="/assets/images/icon/white/alert.png"
          secondHeaderIcon="/assets/images/icon/white/v.png"
          primaryColor="#EB5252"
          firstButtonText="아니요"
          secondButtonText="예"
          body={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text color="#000">
                <Text color="#000" fontFamily="Noto Sans KR-Bold">
                  [{selectedToggleBtn.name}]
                </Text>
                &nbsp;에 대한 자동알림 설정을 정말로 삭제하시겠습니까?
              </Text>
              <Spacer size={3} />
            </div>
          }
          secondBody={<Text color="#000">설정 삭제가 완료되었습니다.</Text>}
          warningText="* 삭제 후 복구는 불가합니다."
          handleFirstButton={closeNoticeModal}
          handleSecondButton={deleteAutoMessageSetting}
        />
      )}
      {mbClicked && selectedToggleBtn && (
        <MessageSettingModal
          closeSetAddModal={closeModifyModal}
          jgroupData={jgroupData}
          selectedNumber={selectedNumber}
          selectedData={selectedToggleBtn}
          isModify={true}
        />
      )}
      <Spacer height="16px"></Spacer>
      <ContentBox flex={1} alignItems="flex-start">
        <ContentBox alignItems="baseline" flex={1} justifyContent="none">
          <LeftContent style={{ display: "flex" }}>
            <Text>발신번호</Text>
          </LeftContent>
          <RightContent alignItems="none" direction="column" paddingLeft="26px">
            <div style={{ display: "flex" }}>
              <Select
                onChange={senderNumberChange}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                // style={{ zIndex: 10002 }}
                // defaultValue={certificateDataList[0].phone_number}
                placement="bottomLeft"
                value={
                  selectedNumber
                    ? selectedNumber.replace(
                        /^(\d{3})(\d{4})(\d{4})$/,
                        "$1-$2-$3"
                      )
                    : certificateDataList && certificateDataList.length > 0
                    ? "발신번호를 선택해주세요"
                    : "발신번호를 추가해주세요."
                }
              >
                {certificateDataList.length > 0 &&
                  certificateDataList.map((item) => (
                    <Select.Option value={item.phone_number} key={item.id}>
                      {item.phone_number}
                    </Select.Option>
                  ))}

                <CustomButton
                  style={{ borderTop: "1px dotted #7C7C7C" }}
                  value="발신번호 추가"
                >
                  발신번호 추가
                </CustomButton>
              </Select>
              {selectedNumber ? (
                <Text
                  color="#65B49F"
                  size={14}
                  fontWeight={700}
                  style={{ marginLeft: "7px", alignSelf: "center" }}
                >
                  인증된 번호입니다.
                </Text>
              ) : null}
            </div>
            {/* {certificateDataList && certificateDataList.length > 0 ? (
              <Text color="#65B49F" size={14} fontWeight={700}>
                &middot; 인증된 번호입니다.
              </Text>
            ) : (
              <Text color="#EB5252" size={14} fontWeight={700}>
                &middot; 인증된 번호가 없는 경우, 운톡푸쉬알림으로만 전송
                가능합니다.
              </Text>
            )} */}
            <Text color="#EB5252">
              &middot; 선택된 번호로 자동알림이 전송됩니다.
            </Text>
            <Text color="#EB5252">
              &middot; 발신번호 미등록시 문자메시지가 전송되지 않습니다.
              *스마트전송 포함
            </Text>
          </RightContent>
        </ContentBox>
        <ContentBox alignItems="baseline" flex={1} justifyContent="none">
          <LeftContent>
            <Text>포인트 현황</Text>
          </LeftContent>
          <RightContent alignItems="none" direction="column" paddingLeft="26px">
            <div
              style={{
                padding: "12px",
                // flex: 1,
                height: "45px",
                display: "flex",
                background: "#4A4A4A",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "5px",
              }}
            >
              <Text>{jgroupData.jgroup_sms_count ?? 0} P</Text>
              <Spacer width="12px" />
              <Text color="#A4A4A4">
                {`(SMS ${Math.floor(
                  jgroupData.jgroup_sms_count / 15
                )}건 /LMS ${Math.floor(
                  jgroupData.jgroup_sms_count / 35
                )}건 발송 가능)`}
              </Text>
              <Spacer width="42px" />
              {/*2차 고도화될 때, 넣기로함. */}
              {/* <CustomButton
                background="#FA943D"
                width="92px"
                height="32px"
                borderRadius="16px"
              >
                <Text>충전하기</Text>
              </CustomButton> */}
            </div>
            <Text color="#EB5252" size={14} fontWeight={700}>
              &middot; 포인트가 부족할 경우 자동알림 발송이 되지 않습니다. (단,
              운톡푸쉬알림 제외)
            </Text>
          </RightContent>
        </ContentBox>
      </ContentBox>
      <div
        style={{
          marginTop: "16px",
          marginBottom: "16px",
          height: "1px",
          background: "#4A4A4A",
        }}
      />
      <ContentBox>
        <ContentBox flex={1}>
          <Title color="#FA943D">설정</Title>
          <ContentBox>
            <Spacer width="12px" />
            <CustomButton
              borderRadius="3px"
              width="100px"
              height="33px"
              background="#4A4A4A"
              onClick={handleUseAllBtn}
            >
              <Text>모두 사용</Text>
            </CustomButton>
            <Spacer width="12px" />
            <CustomButton
              onClick={showSetAddModal}
              borderRadius="3px"
              width="100px"
              height="33px"
              background="#FA943D"
            >
              <Text>설정 추가</Text>
            </CustomButton>
          </ContentBox>
        </ContentBox>
      </ContentBox>
      <Spacer height="16px" />
      <ContentBox direction="column" alignItems="none">
        <ContentBox direction="column" alignItems="none">
          <span style={{ marginTop: "12px", marginBottom: "16px" }}>
            <Text>고객 관련</Text>
          </span>
          <Row
            gutter={[16, 16]}
            // width="100%"
            // flexWrap="wrap"
            // justifyContent="sapce-around"
            // direction="row"
            // alignItems="space-around"
          >
            {customerRelativeSettingList.length > 0 &&
              customerRelativeSettingList.map((data) => {
                const checked =
                  toggleStatusJsonList.length > 0 &&
                  toggleStatusJsonList.filter(
                    (el) => data.auto_message_key === el.auto_message_key
                  );

                return (
                  <Col span={8}>
                    <ToggleBtn
                      key={data.auto_message_key}
                      text={data.name}
                      checked={checked}
                      handleToggleBtn={handleToggleBtn}
                      settingData={data}
                      toggleStatusJsonList={toggleStatusJsonList}
                      openNoticeModal={openNoticeModal}
                      openModifyModal={openModifyModal}
                    />
                  </Col>
                );
              })}
          </Row>
        </ContentBox>
        <ContentBox direction="column" alignItems="none">
          <span style={{ marginTop: "28px", marginBottom: "16px" }}>
            <Text>상품 관련</Text>
          </span>
          <Row gutter={[16, 16]}>
            {productRelativeSettingList.length > 0 &&
              productRelativeSettingList.map((data) => {
                const checked =
                  toggleStatusJsonList.length > 0 &&
                  toggleStatusJsonList.filter(
                    (el) => data.auto_message_key === el.auto_message_key
                  );
                return (
                  <Col span={8}>
                    <ToggleBtn
                      key={data.auto_message_key}
                      text={data.name}
                      checked={checked}
                      handleToggleBtn={handleToggleBtn}
                      settingData={data}
                      toggleStatusJsonList={toggleStatusJsonList}
                      openNoticeModal={openNoticeModal}
                      openModifyModal={openModifyModal}
                    />
                  </Col>
                );
              })}
          </Row>
        </ContentBox>
      </ContentBox>
      {isSetAddModalOpen && (
        <MessageSettingModal
          closeSetAddModal={closeSetAddModal}
          jgroupData={jgroupData}
          selectedNumber={selectedNumber}
        />
      )}
      {isAddSenderNumModalOpen && (
        <AddSenderNumberModal
          jgroupData={jgroupData}
          closeAddSenderNumberModal={closeAddSenderNumberModal}
        />
      )}
      {isSenderNumberChangeModal && (
        <NoticeModal
          multipleScreen={false}
          multipleButton
          title="발신번호 변경 안내"
          headerIcon="/assets/images/icon/white/alert.png"
          primaryColor="#F6CA4F"
          firstButtonText="취소"
          secondButtonText="확인"
          warningText="* 번호 변경에 유의바랍니다."
          body={<Text color="#000">선택한 번호로 전체 알림이 전송됩니다.</Text>}
          handleFirstButton={() => setIsSenderNumberChangeModal(false)}
          handleSecondButton={async () => {
            await patchCallingNumber({
              jgroup_key: jgroupKey,
              defaultNumber: selectedSenderNumber,
            });

            setIsSenderNumberChangeModal(false);
            setIsChangeCompleteModal(true);
          }}
        />
      )}
      {isChangeCompleteModal && (
        <NoticeModal
          multipleScreen={false}
          multipleButton={false}
          title="발신번호 변경 완료"
          headerIcon="/assets/images/icon/white/v.png"
          primaryColor="#78B2A0"
          firstButtonText="확인"
          body={<Text color="#000">발신번호 변경이 완료되었습니다.</Text>}
          warningText="* 발신번호 선택시 다시 변경이 가능합니다."
          handleFirstButton={() => {
            document.body.style.overflow = "unset";
            dispatch(getJgroupAction({ jgroup_key: jgroupKey }));
            setIsChangeCompleteModal(false);
          }}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background-color: aqua; */
`;

const Spacer = styled.div`
  height: ${(props) => (props.height != null ? `${props.height}` : 0)};
  width: ${(props) => (props.width != null ? `${props.width}` : 0)};
`;

const ContentBox = styled.div`
  width: ${(props) => (props.width ? props.width : null)};
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : null)};
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : "nowrap")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-between"};
  /* justify-content: space-between; */
  /* align-items: ; */
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  background-color: ${(props) => (props.background ? props.background : null)};
  padding: ${(props) => (props.padding ? props.padding : null)};
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  /* background-color: beige; */
  flex: ${(props) => (props.flex ? props.flex : null)};
  padding: ${(props) => (props.padding ? props.padding : null)};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : null)};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : null)};
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : null};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : null)};
`;

const RightContent = styled.div`
  /* width: 800px; */
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-between"};
  flex: ${(props) => (props.flex ? props.flex : null)};
  padding: ${(props) => (props.padding ? props.padding : null)};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : null)};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : null)};
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : null};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : null)};
`;

const CustomButton = styled.button`
  width: ${(props) => (props.width ? props.width : null)};
  height: ${(props) => (props.height ? props.height : null)};
  background: ${(props) => (props.background ? props.background : null)};
  border: ${(props) => (props.border ? props.border : "none")};
  border-color: ${(props) => (props.borderColor ? props.borderColor : "none")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0px"};
  padding: ${(props) => (props.padding ? props.padding : null)};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;
