import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CouponHistory } from "./CouponHistory";
import CustomSpinner from "./CustomSpinner";

export const HistoryColumn = ({ record }) => {
  const [hbClicked, setHbClicked] = useState(false); //(h)istory(b)utton
  const getIssuedCouponLoading = useSelector(
    (state) => state.couponReducer.getIssuedCouponLoading
  );
  /** 쿠폰 수정 모달창을 여는 함수 */
  const openCouponHistroyModal = () => {
    document.body.style.overflow = "hidden";
    setHbClicked((hbClicked) => !hbClicked);
  };
  /** 쿠폰 수정 모달창을 여는 함수 */
  const closeCouponHistroyModal = () => {
    document.body.style.overflow = "unset";
    setHbClicked((hbClicked) => !hbClicked);
  };
  return (
    <CouponHistoryColumn>
      <HistoryButton onClick={openCouponHistroyModal}>내역 확인</HistoryButton>
      {getIssuedCouponLoading && <CustomSpinner />}
      {hbClicked && (
        <CouponHistory handleChange={closeCouponHistroyModal} record={record} />
      )}
    </CouponHistoryColumn>
  );
};

const HistoryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  border-radius: 16px;
  font-size: 12px;
  line-height: 17px;
  background: #4a4a4a;
  color: #fff;
  height: 32px;
  width: 71px;
  :hover {
    cursor: pointer;
  }
`;

const CouponHistoryColumn = styled.div`
  font-family: "Noto Sans KR-Regular";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
