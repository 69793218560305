import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  updateFilteredCouponAction,
  updateFilteredIssuedCouponAction,
  updateIsUseCouponAction,
} from "../reducers/coupon";
import { Container, Icon, Spacer } from "../styles/commonStyle";
import { commonTheme } from "../styles/theme";
import CustomInput from "./CustomInput";

/** 키워드를 입력하는 탐색바 */
const CouponSearchBar = ({
  isIssued,
  couponList,
  filteredCouponList,
  issuedCouponList,
  filteredIssuedCouponList,
  setFilter,
}) => {
  function handleSearchTextChange(e) {
    setFilter(e.target.value);
  }

  return (
    <Container>
      <CustomInput
        suffix={
          <Icon
            style={{ width: "24px", height: "24px" }}
            src={
              process.env.PUBLIC_URL + "/assets/images/icon/button/search.png"
            }
          />
        }
        placeholder={isIssued ? "수신인 검색" : "쿠폰명 검색"}
        width="232px"
        height="32px"
        border="none"
        borderRadius="3px"
        background="#4a4a4a"
        onChange={handleSearchTextChange}
      />

      <Spacer />
      <SearchBarLabel>조회된 목록</SearchBarLabel>
      <Spacer />
      {/* 필터링된 결과가 들어가야 하기 때문에 이후 filteredDatas라는 변수를 넣을
      예정 */}
      <SearchResult>
        {isIssued
          ? filteredIssuedCouponList
            ? filteredIssuedCouponList.length
            : issuedCouponList
            ? issuedCouponList.length
            : null
          : filteredCouponList
          ? filteredCouponList.length
          : couponList
          ? couponList.length
          : null}
        건
      </SearchResult>
    </Container>
  );
};

const SearchResult = styled.label`
  display: inline-block;
  color: ${commonTheme.font.black5};
`;

const SearchBarLabel = styled.label`
  display: inline-block;
  color: ${commonTheme.font.black3};
`;

export default CouponSearchBar;
