import { Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  messageColumns,
  pushNotificationColumn,
} from "./MessageTableColumnData";

const MessageTable = ({
  tab,
  tableData,
  handleIndex,
  index,
  totalIndex,
  scrollPosition,
  setScrollPosition,
  height,
}) => {
  const tableRef = useRef(null);
  const getPushNotificationHistoryDone = useSelector(
    (state) => state.messageReducer.getPushNotificationHistoryDone
  );
  // const [height, setHeight] = useState(parent.current.offsetHeight - 42);
  const messageRowKey = (record, index) => {
    return index;
  };

  const pushRowKey = (record, index) => {
    return index;
  };

  const handleScroll = (currentScroll) => {
    setScrollPosition(currentScroll);
  };

  /** table에 이벤트 리스너를 추가
   * @todo 교차 관찰자를 통해, 무한 스크롤을 구현하는 것이 성능 개선에 도움이 될 수 있음
   */
  useEffect(() => {
    if (getPushNotificationHistoryDone && tableRef.current) {
      let table = tableRef.current.querySelector(".ant-table-body");
      const maxScroll = table.scrollHeight - table.clientHeight;

      const scrollEvent = (event) => {
        const currentScroll = table.scrollTop;
        if (currentScroll === maxScroll && index < totalIndex) {
          handleScroll(currentScroll);
          handleIndex();
        }
      };
      table.addEventListener("scroll", scrollEvent);
      if (scrollPosition < maxScroll) {
        table.scrollTop = scrollPosition;
      }
      // Unmount 안할 시, 이벤트 리스너가 중첩되면서 메모리 누수가 발생합니다
      return () => {
        table.removeEventListener("scroll", scrollEvent);
      };
    }
  }, [tableData]);

  return (
    <React.Fragment>
      {tableData &&
        (tab === "message" ? (
          <Table
            // scroll={{ y: parent.current.offsetHeight - 42 }}
            scroll={{ y: height }}
            dataSource={tableData}
            columns={messageColumns}
            pagination={false}
            rowKey={messageRowKey}
            rowHeight={42}
            key="message"
          />
        ) : (
          <Table
            ref={tableRef}
            // scroll={{ y: parent.current.offsetHeight - 42 }}
            scroll={{ y: height }}
            dataSource={tableData}
            columns={pushNotificationColumn}
            pagination={false}
            rowKey={pushRowKey}
            rowHeight={42}
            key="push_notification"
          />
        ))}
    </React.Fragment>
  );
};

export default MessageTable;
