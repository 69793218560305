import axios from "axios";
import { Api } from "./Api";

export const getJgroup = async (data) => {
  const response = await axios.get(
    `${Api.ServerJSON.getInstance().BROJ_SERVER}jgroup/api/jgroup?jgroup_key=${
      data.jgroup_key
    }`
  );
  return response;
};
