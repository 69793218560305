import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  CloseButton,
  ModalScreen,
  PositionController,
  Spacer,
  Text,
} from "../styles/commonStyle";

/** 쿠폰 */
const CouponPreviewModal = ({
  handleChange,
  previewName,
  couponType,
  previewGift,
  previewGiftType,
  previewPeriodType,
  previewPreiodDay,
  previewPeriod,
}) => {
  const [periodEnd, setPeriodEnd] = useState(null);
  useEffect(() => {
    previewPeriod &&
      previewPeriod.length === 2 &&
      setPeriodEnd(moment(previewPeriod[1]).format("YYYY.MM.DD"));
  }, [previewPeriod]);
  return (
    <ModalScreen>
      <PositionController>
        <PreviewContainer>
          <PreviewHeader>
            <Text color={"#fff"} size={18} fontFamily="Noto Sans KR-Bold">
              쿠폰 발행 시 미리보기
            </Text>
            <CloseButton type="button" onClick={handleChange}>
              <CloseIcon
                src={process.env.PUBLIC_URL + "/assets/images/icon/white/x.png"}
              />
            </CloseButton>
          </PreviewHeader>
          <Spacer size={12} />
          <PreviewWrapper>
            <Spacer size={3} />
            <StyledBar />
            <Spacer size={15} />
            <Text
              size={28}
              color={"#000"}
              fontFamily="Noto Sans KR"
              fontWeight={700}
              lineHeight={38}
              style={{ textAlign: "center" }}
            >
              홍길동 회원님께 <br />
              쿠폰이 발급되었습니다!
            </Text>
            <Spacer aria-setsize={11.5} />
            <PreviewCoupon>
              <CouponName>
                {previewName ? previewName : "입력한 쿠폰명"}
              </CouponName>
              <ExpirationDate>
                {previewPeriodType === "SPECIFIC" && periodEnd
                  ? `${periodEnd}까지 사용`
                  : previewPeriodType === "DAYS" && previewPreiodDay
                  ? `발행일로부터 ${previewPreiodDay}일 이내`
                  : "입력한 유효기간"}
              </ExpirationDate>
              <DiscountType>
                {(() => {
                  switch (couponType.value) {
                    case "QR_EXCHANGE":
                      return "교환권";
                    case "MARKET_DISCOUNT":
                      if (previewGift) {
                        switch (previewGiftType) {
                          case "DISCOUNT_PER":
                            return `${previewGift}% 할인`;
                          case "DISCOUNT_PRICE":
                            return `${previewGift}원 할인`;
                        }
                      } else {
                        return "할인";
                      }
                  }
                })()}
              </DiscountType>
            </PreviewCoupon>
            <Spacer size={12} />
            <Text color="#000">해당 쿠폰은 운톡 마켓에서 사용 가능하며</Text>
            <Text color="#000">유효기간 내 사용이 가능합니다</Text>
          </PreviewWrapper>
        </PreviewContainer>
      </PositionController>
    </ModalScreen>
  );
};

const PreviewContainer = styled.div`
  width: 450px;
  height: 500px;
  padding: 30px;
  background: #2c2c2c;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  text-align: start;
  position: relative;
`;

const PreviewHeader = styled.div`
  height: 24px;
`;

const PreviewWrapper = styled.div`
  width: 390px;
  height: 401px;
  background: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px 30px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledBar = styled.div`
  width: 68px;
  height: 1px;
  border: 4px solid #e7ebee;
  border-radius: 5px;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
`;

const PreviewCoupon = styled.div`
  width: 320px;
  height: 160px;
  background: #22d8b3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
`;

const CouponName = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  padding-left: 25px;
  padding-top: 27px;
`;

const ExpirationDate = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  padding-left: 25px;
`;

const DiscountType = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 61px;
  color: #ffffff;
  right: 24px;
  bottom: 7px;
  position: absolute;
`;

export default CouponPreviewModal;
