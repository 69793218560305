import React from "react";
import { Form, Input, Dropdown, Menu } from "antd";
import { Text, Icon, CloseButton } from "../styles/commonStyle";
import { CustomButton } from "./CustomButton";
import styled, { keyframes } from "styled-components";
import { commonTheme } from "../styles/theme";
import { validateSendHour } from "./Validator";
const ScheduleTypeData = ({
  idx,
  selectedOption,
  dateCountTiming,
  dateCount,
  handleDateCountChange,
  // dateCountTypeList,
  dateCountType,
  // dateCountTimingList,
  // dateCountTimingTypeList,
  dateCountTimingType,
  handleSendHourChange,
  sendHour,
  handleDateCountTypeChange,
  handleDateCountTimingChange,
  hangleDateCountTimingTypeChange,
  sendHourInputRef,
  setScheduleTypeList,
  scheduleTypeList,
  handleSendMinuteChange,
  sendMinute,
}) => {
  const MenuItem = Menu.Item;

  const menuStyle = {
    backgroundColor: "#4a4a4a",
  };

  const menuItemStyle = {
    background: "none",
    color: "#ffffff",
  };
  const dateCountTypeList = (
    <Menu style={menuStyle} onClick={(e) => handleDateCountTypeChange(e, idx)}>
      <MenuItem key="day" style={menuItemStyle}>
        일
      </MenuItem>
      <MenuItem key="week" style={menuItemStyle}>
        주
      </MenuItem>
    </Menu>
  );

  const dateCountTimingList = (
    <Menu
      style={menuStyle}
      onClick={(e) => handleDateCountTimingChange(e, idx)}
    >
      {selectedOption.function_type === "MEMBER_TICKET_EXPIRE_DATE_ALARM" ||
      selectedOption.function_type === "LESSON_TICKET_EXPIRE_DATE_ALARM" ||
      selectedOption.function_type === "CUSTOMER_BIRTHDAY_ALARM" ||
      selectedOption.function_type === "ACTIVE_CUSTOMER_ALARM" ||
      selectedOption.function_type === "INACTIVE_CUSTOMER_ALARM" ? null : (
        <MenuItem key="minus" style={menuItemStyle}>
          전
        </MenuItem>
      )}
      {selectedOption.function_type === "MEMBER_TICKET_EXPIRE_DATE_ALARM" ||
      selectedOption.function_type === "LESSON_TICKET_EXPIRE_DATE_ALARM" ||
      selectedOption.function_type === "CUSTOMER_BIRTHDAY_ALARM" ||
      selectedOption.function_type === "ACTIVE_CUSTOMER_ALARM" ||
      selectedOption.function_type === "INACTIVE_CUSTOMER_ALARM" ? null : (
        <MenuItem key="plus" style={menuItemStyle}>
          후
        </MenuItem>
      )}

      <MenuItem key="equal" style={menuItemStyle}>
        당일
      </MenuItem>
    </Menu>
  );

  const dateCountTimingTypeList = (
    <Menu
      style={menuStyle}
      onClick={(e) => hangleDateCountTimingTypeChange(e, idx)}
    >
      <MenuItem key="afternoon" style={menuItemStyle}>
        오후
      </MenuItem>
      <MenuItem key="mornig" style={menuItemStyle}>
        오전
      </MenuItem>
    </Menu>
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: idx && idx > 0 ? "6px" : "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width:
            (selectedOption &&
              selectedOption.function_type === "CUSTOMER_NON_ATTENDANCE") ||
            (selectedOption &&
              selectedOption.function_type === "CUSTOMER_HOLDING_EXPIRE_ALARM")
              ? "95%"
              : "95%",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Text
          fontWeight={700}
          style={{
            display: "flex",
            flexDirection: "row",
            // width: "100%",
            alignItems: "ceter",
            justifyContent: "center",
          }}
        >
          {selectedOption &&
          selectedOption.function_type === "CUSTOMER_NON_ATTENDANCE"
            ? "마지막 출석일로부터"
            : selectedOption.function_type === "CUSTOMER_COUPON_EXPIRE_ALARM"
            ? "쿠폰 만료"
            : selectedOption.function_type === "CUSTOMER_BIRTHDAY_ALARM"
            ? "생일"
            : selectedOption.function_type === "ACTIVE_CUSTOMER_ALARM"
            ? ""
            : selectedOption.function_type === "INACTIVE_CUSTOMER_ALARM"
            ? ""
            : selectedOption && selectedOption.label}
        </Text>
        <Form.Item>
          <Input
            style={{
              width: "50px",
              height: "45px",
              textAlign: "center",
              opacity:
                dateCountTiming === "EQUAL" ||
                selectedOption.function_type ===
                  "MEMBER_TICKET_EXPIRE_DATE_ALARM" ||
                selectedOption.function_type ===
                  "LESSON_TICKET_EXPIRE_DATE_ALARM"
                  ? "50%"
                  : null,
            }}
            disabled={
              dateCountTiming === "EQUAL" ||
              selectedOption.function_type ===
                "MEMBER_TICKET_EXPIRE_DATE_ALARM" ||
              selectedOption.function_type === "LESSON_TICKET_EXPIRE_DATE_ALARM"
                ? true
                : false
            }
            autoComplete="off"
            value={dateCount}
            onChange={(e) => handleDateCountChange(e, idx)}
          />
        </Form.Item>
        <Form.Item>
          <Dropdown
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            popupStyle={{ zIndex: 10500 }}
            trigger={["click"]}
            overlay={dateCountTypeList}
            // position="relative"
            overlayStyle={{ position: "absolute" }}
            disabled={
              dateCountTiming === "EQUAL" ||
              selectedOption.function_type ===
                "MEMBER_TICKET_EXPIRE_DATE_ALARM" ||
              selectedOption.function_type === "LESSON_TICKET_EXPIRE_DATE_ALARM"
                ? true
                : false
            }
            style={{ height: "100%" }}
          >
            <div
              style={{
                opacity:
                  dateCountTiming === "EQUAL" ||
                  selectedOption.function_type ===
                    "MEMBER_TICKET_EXPIRE_DATE_ALARM" ||
                  selectedOption.function_type ===
                    "LESSON_TICKET_EXPIRE_DATE_ALARM"
                    ? "50%"
                    : null,
                background: "#4a4a4a",
                padding: "12px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "74px",
                justifyContent: "space-between",
                height: "100%",
                borderRadius: "5px",
              }}
            >
              <Text>
                {dateCountType === "DAY"
                  ? "일"
                  : dateCountType === "WEEK"
                  ? "주"
                  : ""}
              </Text>
              <Icon
                width="12px"
                height="6px"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icon/select/toggle_down.png"
                }
              />
            </div>
          </Dropdown>
        </Form.Item>
        <Form.Item>
          <Dropdown
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            popupStyle={{ zIndex: 10500 }}
            trigger={["click"]}
            overlay={dateCountTimingList}
            // position="relative"
            disabled={
              selectedOption &&
              selectedOption.function_type === "CUSTOMER_NON_ATTENDANCE"
                ? true
                : false
            }
            overlayStyle={{ position: "absolute" }}
            style={{
              height: "100%",
            }}
          >
            <div
              style={{
                background: "#4a4a4a",
                padding: "12px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "74px",
                justifyContent: "space-between",
                height: "100%",
                borderRadius: "5px",
                opacity:
                  selectedOption &&
                  selectedOption.function_type === "CUSTOMER_NON_ATTENDANCE"
                    ? "50%"
                    : null,
              }}
            >
              <Text>
                {dateCountTiming === "MINUS"
                  ? "전"
                  : dateCountTiming === "PLUS"
                  ? "후"
                  : dateCountTiming === "EQUAL"
                  ? "당일"
                  : ""}
              </Text>
              <Icon
                width="12px"
                height="6px"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icon/select/toggle_down.png"
                }
              />
            </div>
          </Dropdown>
        </Form.Item>
        <Form.Item>
          <Dropdown
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            popupStyle={{ zIndex: 10500 }}
            trigger={["click"]}
            overlay={dateCountTimingTypeList}
            // position="relative"
            overlayStyle={{ position: "absolute" }}
            style={{ height: "100%" }}
          >
            <div
              style={{
                background: "#4a4a4a",
                padding: "12px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "74px",
                justifyContent: "space-between",
                height: "100%",
                borderRadius: "5px",
              }}
            >
              <Text>
                {dateCountTimingType === "AFTERNOON"
                  ? "오후"
                  : dateCountTimingType === "MORNING"
                  ? "오전"
                  : ""}
              </Text>
              <Icon
                width="12px"
                height="6px"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icon/select/toggle_down.png"
                }
              />
            </div>
          </Dropdown>
        </Form.Item>
        <Form.Item
        // initialValue={sendHour}
        // name="send-hour"
        // rules={[
        //   {
        //     validator: (rule, sendHour, callback) => {
        //       if (sendHour === "" && dateCountTimingType === "MORNING") {
        //         callback("시간을 입력해주세요.");
        //       }
        //       if (sendHour === "" && dateCountTimingType === "AFTERNOON") {
        //         callback("시간을 입력해주세요.");
        //       }
        //       if (
        //         Number(sendHour) === 0 &&
        //         dateCountTimingType === "AFTERNOON"
        //       ) {
        //         callback("'오후' 선택시 0시는 입력할 수 없습니다.");
        //       }
        //     },
        //   },
        // ]}
        >
          <Input
            ref={sendHourInputRef}
            onBlur={() => {
              if (Number(scheduleTypeList[idx].sendHour) === 0) {
                let newList = scheduleTypeList;
                newList[idx].sendHour = 10;
                setScheduleTypeList([...newList]);
              }
            }}
            // name="send-hour"
            style={{
              width: "50px",
              height: "45px",
              textAlign: "center",
            }}
            autoComplete="off"
            value={sendHour}
            onChange={(e) => handleSendHourChange(e, idx)}
          />
        </Form.Item>
        <Text
          fontWeight={700}
          style={{
            display: "flex",
            flexDirection: "row",
            // width: "100%",
            alignItems: "ceter",
            justifyContent: "center",
          }}
        >
          시
        </Text>

        <Input
          // ref={sendHourInputRef}
          // onBlur={() => {
          //   if (Number(scheduleTypeList[idx].sendHour) === 0) {
          //     let newList = scheduleTypeList;
          //     newList[idx].sendHour = 10;
          //     setScheduleTypeList([...newList]);
          //   }
          // }}
          // name="send-hour"
          style={{
            width: "50px",
            height: "45px",
            textAlign: "center",
          }}
          autoComplete="off"
          value={sendMinute}
          onChange={(e) => handleSendMinuteChange(e, idx)}
        />
        <Text
          fontWeight={700}
          style={{
            display: "flex",
            flexDirection: "row",
            // width: "100%",
            alignItems: "ceter",
            justifyContent: "center",
          }}
        >
          분에 전송됩니다
        </Text>
        <CloseButton
          onClick={() => {
            let newScheduleTypeList = [...scheduleTypeList];
            if (newScheduleTypeList && newScheduleTypeList.length > 0) {
              if (newScheduleTypeList.length <= 1) {
                return;
              }
              newScheduleTypeList.splice(idx, 1);
              setScheduleTypeList(newScheduleTypeList);
            }
          }}
          type="button"
        >
          <CloseIcon
            src={process.env.PUBLIC_URL + "/assets/images/icon/white/x.png"}
          ></CloseIcon>
        </CloseButton>
      </div>
      {/* <div style={{ marginTop: "12px" }}>
        <CustomStyleBtn
          style={{ padding: "12px 85px 12px 85px" }}
          background="#4A4A4A"
          borderRadius="22px"
        >
          <Icon
            width="20px"
            height="20px"
            src={process.env.PUBLIC_URL + "/assets/images/icon/white/plus.png"}
            style={{ marginRight: "6px" }}
          />

          <Text fontWeight="700">전송시간 추가</Text>
        </CustomStyleBtn>
      </div> */}
    </div>
  );
};
const antMoveDownIn = keyframes`
  0% {
    transform-origin: 0 0;
    transform: translateY(0);
    opacity: 1;
  }
  100%{
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const antMoveDownOut = keyframes`
  0% {
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    transform-origin: 0 0;
    transform: translateY(0);
    opacity: 1;
  }
`;

const ButtonStyle = `
flex-grow: 1;
border: none;
font-family: "Noto Sans KR";
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 29px;
text-align: center;
letter-spacing: -0.670707px;
:hover {
  cursor: pointer;
}
`;

const CustomSelect = styled.div`
  width: 329px;
  height: 45px;
  color: #a4a4a4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #4a4a4a;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 12px;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  cursor: pointer;
`;

const SelectProductWrapper = styled.div`
  width: 100%;
  z-index: 10500;
  position: relative;
  height: 0;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-name: ${(props) =>
    props.enabled ? antMoveDownOut : antMoveDownIn};
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const RightContent = styled.div`
  width: 681px;
  display: flex;
  align-items: center;
`;

const CloseIcon = styled.img`
  /* position: absolute; */
  /* top: 24px; */
  /* right: 24px; */
  width: 16px;
  height: 16px;
`;

const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  background: ${commonTheme.background.black4};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px;
  padding-bottom: 0;
  z-index: 100;
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
`;

const RightHeader = styled.div`
  display: flex;
  align-items: center;
`;

const FormContainer = styled.div`
  position: relative;
  background: ${commonTheme.background.black4};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  width: 900px;
  height: 671px;
  overflow: auto;
  /* padding: 30px; */
  /* padding-bottom: 0px; */
`;

const FormWrapper = styled.div`
  width: 100%;
  padding: 30px;

  padding-top: 0px;
  padding-bottom: 0px;
`;

const CustomStyleBtn = styled.button`
  width: ${(props) => (props.width ? props.width : null)};
  height: ${(props) => (props.height ? props.height : null)};
  background: ${(props) => (props.background ? props.background : null)};
  border: ${(props) => (props.border ? props.border : "none")};
  border-color: ${(props) => (props.borderColor ? props.borderColor : "none")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0px"};
  padding: ${(props) => (props.padding ? props.padding : null)};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;
export default ScheduleTypeData;
