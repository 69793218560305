import { Table } from "antd";
import React from "react";
import styled from "styled-components";

/** 적용 상품 모달창에 보여지는 테이블
 * @param tableData 적용 상품 테이블에 들어갈 데이터
 */
const CouponProductTable = ({ tableData }) => {
  const rowKey = (record) => {
    return record.goods_key;
  };

  return (
    <Table
      scroll={{ y: 510 }}
      dataSource={tableData}
      columns={productColumns}
      pagination={false}
      rowKey={rowKey}
      rowHeight={42}
      key="product"
    />
  );
};

const productColumns = [
  {
    title: "No",
    dataIndex: "key",
    key: "key",
    width: 56,
    render: (key, record, index) => <CustomColumn>{index + 1}</CustomColumn>,
  },
  {
    title: "상품명",
    dataIndex: "product_name",
    key: "product_name",
    width: 500,
    render: (_, record) => (
      <CustomColumn>{record.name || record.product_name}</CustomColumn>
    ),
  },
];

const commonColumnStyle = `
font-family: "Noto Sans KR-Regular";
font-style: normal;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
justify-content: center;
text-align: center; `;

const CustomColumn = styled.div`
  ${commonColumnStyle}
`;

export default CouponProductTable;
