import produce from "immer";

/* ACTION 정의 */
export const GET_JGROUP = "GET_JGROUP";
export const GET_JGROUP_SUCCESS = "GET_JGROUP_SUCCESS";
export const GET_JGROUP_FAILURE = "GET_JGROUP_FAILURE";

export const GET_JGROUPKEY_COOKIE = "GET_JGROUPKEY_COOKIE";

/* ACTION 등록 */
/* jgroup data 가져오는 api */
export const getJgroupAction = (data) => ({
  type: GET_JGROUP,
  data,
});
export const getJgroupKey = (data) => ({
  type: GET_JGROUPKEY_COOKIE,
  data,
});

export const initialState = {
  getJgroupDataLoading: false,
  getjgroupDataDone: false,
  getjgroupDataError: false,
  jgroupData: {},
  jgroup_key_inCookie: null,
};

const groupReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_JGROUP:
        draft.getjgroupDataDone = false;
        draft.getjgroupDataError = null;
        draft.getjgroupDataDone = true;
        break;
      case GET_JGROUP_SUCCESS:
        let _jgroupData = action.data;
        draft.jgroupData = _jgroupData.data.jgroup;
        draft.getjgroupDataDone = true;
        draft.getjgroupDataError = false;
        break;
      case GET_JGROUP_FAILURE:
        draft.getjgroupDataError = action.error;
        draft.getJgroupDataLoading = false;
        break;
      case GET_JGROUPKEY_COOKIE:
        draft.jgroup_key_inCookie = action.data;
        break;
      default:
        break;
    }
  });
};
export default groupReducer;
